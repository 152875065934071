import { create } from "zustand";
import { addProductToCart, removeProductFromCart } from "../helpers/api/cart";

const cartResetState = {
  isCartEmpty: false,
  cartItems: {},
  cartCosting: 0,
  cartItemCount: 0,
  showGoToCart: false,
  discountDetails: { code: "", amount: 0, error: "", showPopup: false },
  discountOffers: [],
  showDiscountOffersDrawer: false,
  abndDiff: 0,
  comboUpgrade: null,
};

export const useCartStore = create((set, get) => ({
  isCartEmpty: false,
  cartItems: {},
  cartItemCount: 0,
  outOfStockIds: [],
  isLoggedIn: false,
  // showGoToCart: false,
  // discountDetails: { code: "", amount: 0, error: "", showPopup: false },
  // discountOffer: [],
  // showDiscountOfferDrawer: false,
  // cartCosting: 0,
  // abndDiff: 0,
  // comboUpgrade: null,

  addToCart: async (variantId, blockInfo = {}, isLighteningItem = false) => {
    const payload = { id: variantId, quantity: 1, is_litem: isLighteningItem };
    try {
      const data = await addProductToCart(payload);
      let finalObj = {};
      let obj = {
        qty: data.data.total_quantity,
        quantity: data.data.quantity,
        // image_url: data.data.image,
        // product_title: data.data.title,
        // variant_name: data.data.variant_title,
        // product_handle: data.data.product_handle,
        // total_mrp: data.data.total_mrp,
        // total_sp: data.data.total_sp,
        // is_free_product: data.data.is_free_product,
        // expired_at: data.data.expired_at,
        // promotion_quantity: data.data.promotion_quantity,
        // total_non_prom_sp: data.data.total_non_prom_sp,
        // total_non_prom_mrp: data.data.total_non_prom_mrp,
        // total_prom_mrp: data.data.total_prom_mrp,
        // total_prom_sp: data.data.total_prom_sp,
      };
      finalObj[variantId] = obj;
      set((state) => ({
        cartItems: { ...state.cartItems, ...finalObj },
        cartItemCount: data.total_cart_items,
        // showGoToCart: true,
        // abndDiff: data.abnd_check ? data.abnd_diff : data.abnd_diff + 1,
      }));

      // if (get().isCartEmpty) {
      //   set({ isCartEmpty: false });
      // }

      // if (data.discounting) {
      //   if (data.discounting.status === "SUCCESS") {
      //     if (data.discounting.error_response) {
      //       set((state) => ({
      //         cartCosting: data.discounting.data.costs,
      //         discountDetails: {
      //           ...state.discountDetails,
      //           error: data.discounting.error_response,
      //         },
      //       }));
      //     } else {
      //       set((state) => ({
      //         cartCosting: data.discounting.data.costs,
      //         discountDetails: {
      //           ...state.discountDetails,
      //           code: data.discounting.data.discount_code,
      //           amount: data.discounting.data.costs.discount_amount,
      //           error: "",
      //         },
      //       }));
      //     }
      //   }
      // } else {
      //   set({
      //     cartCosting: data.costs,
      //     discountDetails: { code: "", amount: 0, showPopup: false, error: "" },
      //   });
      // }

      // if (data.suggested_combo_details) {
      //   set({
      //     comboUpgrade: data.suggested_combo_details,
      //   });
      // }

      // if (data.data.expired_at) {
      //   useLighteningDealStore.setState({
      //     lighteningTimer: Math.ceil((new Date(data.data.expired_at) - new Date()) / 1000),
      //   });
      // }

      // analytics
      // const offers = get().discountOffers;
      // let ct_applicable_discount = {};
      // if (offers && offers.length > 0) {
      //   ct_applicable_discount = {
      //     isApplicableForDiscount: offers[0].status === "SUCCESS",
      //     discountCode: offers[0].discount_code,
      //     possibleSavings: offers[0].possible_savings ? offers[0].possible_savings : 0,
      //   };
      // }

      // const ct_item = {
      //   ...data.data,
      //   ...data.analytics_costs,
      //   ...blockInfo,
      //   ...ct_applicable_discount,
      //   total_cart_items: data.total_cart_items,
      //   isLighteningItem,
      // };

      // trackAddToCartEvent(ct_item);
    } catch (error) {
      return "Something went worng!";
    }
  },

  removeFromCart: async (variantId, blockInfo = {}, isDeleted = false, isLighteningItem = false) => {
    const existingQty = get().cartItems[variantId].qty;
    const payload = { id: variantId, quantity: isDeleted ? existingQty : 1, is_litem: isLighteningItem };
    try {
      const data = await removeProductFromCart(payload);
      // if (data.total_cart_items > 0) {
      //   await mutate("cartRecommendations");
      //   await mutate("discountOffersCart");
      // }

      if (data.data.total_quantity === 0) {
        set((state) => {
          delete state.cartItems[variantId];
          return {
            cartItems: { ...state.cartItems },
            abndDiff: data.abnd_check ? data.abnd_diff : data.abnd_diff + 1,
          };
        });
      } else {
        let finalObj = {};
        let obj = {
          qty: data.data.total_quantity,
          image_url: data.data.image,
          product_title: data.data.title,
          variant_name: data.data.variant_title,
          product_handle: data.data.product_handle,
          total_mrp: data.data.total_mrp,
          total_sp: data.data.total_sp,
          is_free_product: data.data.is_free_product,
          expired_at: data.data.expired_at,
          quantity: data.data.quantity,
          promotion_quantity: data.data.promotion_quantity,
          total_non_prom_sp: data.data.total_non_prom_sp,
          total_non_prom_mrp: data.data.total_non_prom_mrp,
          total_prom_mrp: data.data.total_prom_mrp,
          total_prom_sp: data.data.total_prom_sp,
        };
        finalObj[variantId] = obj;
        set((state) => ({
          cartItems: { ...state.cartItems, ...finalObj },
          abndDiff: data.abnd_check ? data.abnd_diff : data.abnd_diff + 1,
        }));
      }

      if (data.total_cart_items === 0 || !data.total_cart_items) {
        set({ ...cartResetState, isCartEmpty: true });
        return;
      }

      set({
        cartItemCount: data.total_cart_items,
        showGoToCart: true,
      });

      // if (data.discounting) {
      //   if (data.discounting.status === "SUCCESS") {
      //     if (data.discounting.error_response) {
      //       set((state) => ({
      //         cartCosting: data.discounting.data.costs,
      //         discountDetails: {
      //           ...state.discountDetails,
      //           error: data.discounting.error_response,
      //         },
      //       }));

      //       if (data.discounting.data.is_free_item_removed) {
      //         set((state) => {
      //           const freeItemVariantId = Object.keys(state.cartItems).find(
      //             (id) => state.cartItems[id].is_free_product && !state.cartItems[id].expired_at
      //           );
      //           delete state.cartItems[freeItemVariantId];
      //           return { cartItems: { ...state.cartItems } };
      //         });
      //       }
      //     } else {
      //       set((state) => ({
      //         cartCosting: data.discounting.data.costs,
      //         discountDetails: {
      //           ...state.discountDetails,
      //           code: data.discounting.data.discount_code,
      //           amount: data.discounting.data.costs.discount_amount,
      //           error: "",
      //         },
      //       }));
      //     }
      //   }
      // } else {
      //   set({
      //     cartCosting: data.costs,
      //     discountDetails: { code: "", amount: 0, showPopup: false, error: "" },
      //   });
      // }

      if (data.suggested_combo_details) {
        set({
          comboUpgrade: data.suggested_combo_details,
        });
      }

      // // analytics
      // const offers = get().discountOffers;
      // let ct_applicable_discount = {};
      // if (offers && offers.length > 0) {
      //   ct_applicable_discount = {
      //     isApplicableForDiscount: offers[0].status === "SUCCESS",
      //     discountCode: offers[0].discount_code,
      //     possibleSavings: offers[0].possible_savings ? offers[0].possible_savings : 0,
      //   };
      // }

      // const ct_item = {
      //   ...data.data,
      //   ...blockInfo,
      //   quantity: isDeleted ? existingQty : 1,
      //   ...data.analytics_costs,
      //   ...ct_applicable_discount,
      //   total_cart_items: data.total_cart_items,
      //   isLighteningItem,
      // };

      // trackRemoveFromCart(ct_item);
    } catch (err) {
      return "Something went wrong!";
    }
  },
}));
