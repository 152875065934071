import { useState } from "react";
// import Image from "next/image";
// import dynamic from "next/dynamic";

import { useCartStore } from "../../../../../zustStore/useStore";

import Loader from "../../../../../asset/svg/LoaderIcon";
import HideIcon from "../../../../../asset/svg/HideIcon";
import AddIcon from "../../../../../asset/svg/AddIcon";
// const ToastErrorMessage = dynamic(() => import("@/components/ErrorMessage/ToastErrorMessage"));
import trashIcon from "../../../../../asset/img/trash.svg";
import whiteCartIcon from "../../../../../asset/img/WhiteCart.svg";

const CartItemAddToCart = ({ variantId, typeLarge = false, isLighteningItem = false, addDisabled = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const blockInfo = {
    pageType: "Cart",
    blockIndex: 0,
    blockName: "CartItemsList",
  };

  const items = useCartStore((state) => state.cartItems);
  const addToCart = useCartStore((state) => state.addToCart);
  const removeFromCart = useCartStore((state) => state.removeFromCart);
  const outOfStockIds = useCartStore((state) => state.outOfStockIds);

  const addProduct = async (variantId) => {
    setIsLoading(true);
    const error = await addToCart(variantId, blockInfo, isLighteningItem);
    setIsLoading(false);
    setErrorMessage(error);
  };

  const removeProduct = async (variantId, isDeleted = false) => {
    setIsLoading(true);
    const error = await removeFromCart(variantId, blockInfo, isDeleted, isLighteningItem);
    setIsLoading(false);
    setErrorMessage(error);
  };

  if (outOfStockIds && outOfStockIds.length > 0 && outOfStockIds.includes(variantId)) {
    return (
      <button
        className={`flex flex-row justify-between bg-darkWhite border border-categoryDivider ${
          typeLarge ? "rounded-[8px] w-full h-[48px]" : "rounded w-full h-[32px]"
        }`}
        disabled
      >
        {typeLarge ? (
          <div className="mx-auto my-auto flex flex-row items-center">
            <p className="font-bold text-content-6 text-[14px] leading-[16px] text-center mx-auto uppercase tracking-[0.12em] desktop:whitespace-nowrap">
              SOLD OUT
            </p>
          </div>
        ) : (
          <span
            className={`font-bold text-content-6 text-[14px] leading-[16px] text-center mx-auto uppercase tracking-[0.08em] my-auto `}
          >
            SOLD OUT
          </span>
        )}
      </button>
    );
  }

  return (
    <>
      {items[variantId]?.qty && items[variantId]?.qty > 0 ? (
        <>
          <div className="flex justify-between items-center w-full ">
            <button
              onClick={() => removeProduct(variantId, true)}
              className="relative overflow-hidden w-[24px] h-[24px] cursor-pointer"
              disabled={isLoading}
            >
              <img src={trashIcon} alt="trash-logo" />
            </button>

            <div
              className={`flex rounded border overflow-hidden w-[162px] ${
                isLighteningItem ? "border-lighteningRed" : "border-green"
              }`}
            >
              <button
                onClick={() => removeProduct(variantId)}
                className={`bg-white h-[32px] text-[26px] flex items-center justify-center cursor-pointer ${
                  addDisabled ? "w-[67px]" : "w-[54px]"
                }`}
                disabled={isLoading}
              >
                <div className="w-[20px] h-[20px]">
                  <HideIcon color={isLighteningItem ? "#D2365E" : "#49AD91"} />
                </div>
              </button>
              <div
                className={`bg-green text-white font-bold text-[14px] leading-[16px] h-[32px] flex items-center justify-center ${
                  addDisabled ? "w-[67px]" : "w-[62px]"
                } ${isLighteningItem ? "bg-lighteningRed" : "bg-green"}`}
              >
                {isLoading ? (
                  <Loader width={19} height={19} />
                ) : isLighteningItem ? (
                  items[variantId]?.promotion_quantity
                ) : (
                  items[variantId]?.qty
                )}
              </div>
              {addDisabled ? null : (
                <button
                  onClick={() => addProduct(variantId)}
                  className="bg-white w-[54px] h-[32px] text-[26px] flex items-center justify-center cursor-pointer"
                  disabled={isLoading}
                >
                  <div className="w-[20px] h-[20px]">
                    <AddIcon color={isLighteningItem ? "#D2365E" : "#49AD91"} />
                  </div>
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <button
          className={`flex flex-row justify-between disabled:bg-gray w-full ml-auto ${
            typeLarge ? "rounded-[8px] w-full h-[48px]" : "rounded !w-[163px] px-4 h-[34px]"
          } ${isLighteningItem ? "bg-lighteningRed" : "bg-green"}`}
          onClick={() => addProduct(variantId)}
          disabled={isLoading}
        >
          {!typeLarge ? (
            <div className="mx-auto my-auto flex flex-row items-center">
              <div className="relative overflow-hidden w-[18px] h-[18px]">
                {isLoading ? (
                  <Loader width={18} height={18} color="#FFFFFF" />
                ) : (
                  <img src={whiteCartIcon} alt="cart icon" />
                )}
              </div>
              <p className="font-sans font-bold text-white text-[14px] leading-[16px] text-center mx-auto uppercase pl-[12px] tracking-[0.06em] desktop:whitespace-nowrap">
                Add To Cart
              </p>
            </div>
          ) : (
            <span
              className={`font-sans font-bold text-[#FFFFFF] text-[14px] leading-[16px] text-center mx-auto tracking-[0.08em] my-auto`}
            >
              {isLoading ? <Loader width={18} height={18} /> : "+ ADD"}
            </span>
          )}
        </button>
      )}
    </>
  );
};

export default CartItemAddToCart;
