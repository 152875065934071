import React, { useState } from "react";
import { css } from "@emotion/react";
import Cookies from "js-cookie";
import { authAPI } from "../../helpers/api";

import "./scss/login.scss";
import Layout from "../../components/Layout";
import Timer from "./timer";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

const LoginPage = ({ history, match }) => {
  const [appUiState, setAppUiState] = useState(1); //1=phoneNoPage,2=OTPVerifyPage,3=OptionalInfoPage
  const [phoneNo, setPhoneNo] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loginButtonLoading, setLoginButtonLoading] = useState(false);

  const onPhoneSubmit = async (e) => {
    e.preventDefault();
    if (phoneNo.length !== 10) {
      setError("Please enter ten digits phone number");
      return;
    }
    setError("");
    const { statusCode } = await authAPI.post("/v2/auth/otp/", { phone: phoneNo });

    if (statusCode === 200) {
      if (appUiState === 1) setAppUiState(2);
    } else {
      setError("Failed to send OTP. Try Again");
    }
  };

  const onOTPSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setButtonLoading(true);
    const { data, statusCode } = await authAPI.post("/v3/auth/verify/", { phone: phoneNo, otp });
    if (statusCode === 200) {
      if (!data.is_verified) {
        setError("Please enter correct OTP");
        setButtonLoading(false);
      }
      if (data.is_verified && !data.require_activate) {
        setButtonLoading(false);
        setLoginButtonLoading(false);
        Cookies.set("access_token", data.token.access_token, {
          expires: new Date(new Date().setMonth(new Date().getMonth() + 3)),
          domain: process.env.REACT_APP_HOST_DOMAIN || ".nathabit.in",
        });
        setTimeout(() => {
          window.location = "/pages/order-tracking";
        }, 200);
      }
    } else {
      setError("Failed to send OTP. Try again");
      setButtonLoading(false);
    }
  };

  if (appUiState === 1) {
    return (
      <Layout>
        <div className="PageContent PageContent--fitScreen PageContent--extraNarrow Login-form">
          <form
            // method="post"
            // action="/account/login"
            id="customer_detail"
            acceptCharset="UTF-8"
            name="details"
            className="Form Form--spacingTight"
            style={{ display: "block" }}
            onSubmit={onPhoneSubmit}
          >
            <header className="Form__Header">
              <div className="Form__Item">
                <h1 className="Form__Title Heading u-h1">Login</h1>
              </div>
              <p className="Form__Legend">Please enter your mobile number</p>
            </header>
            {error ? <p className="Alert Alert--error">{error}.</p> : null}
            <div className="Form__Item">
              <input
                type="number"
                className="Form__Input"
                name="phone"
                required="required"
                placeholder="Phone Number"
                aria-label="Phone Number"
                autoFocus=""
                value={phoneNo}
                maxLength="10"
                onInput={(e) => {
                  if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
                }}
                onChange={(e) => {
                  setError("");
                  setPhoneNo(e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
                }}
                autoComplete="false"
              ></input>
            </div>
            <button
              type="submit"
              label="Proceed"
              className="Form__Submit Button Button--primary Button--full"
              // disabled={phoneNo.length == 10 ? false : true}
            >
              Login
            </button>
          </form>
        </div>
      </Layout>
    );
  } else if (appUiState === 2) {
    return (
      <Layout>
        <div className="PageContent PageContent--fitScreen PageContent--extraNarrow Login-form">
          <form
            // method="post"
            // action="/account/login"
            id="customer_otp"
            acceptCharset="UTF-8"
            name="otp_form"
            className="Form Form--spacingTight"
            style={{ display: "block" }}
            onSubmit={onOTPSubmit}
          >
            <header className="Form__Header Form__Item">
              <div className="Form__Item">
                <h1 className="Form__Title Heading u-h1">Verify OTP</h1>
              </div>
              <p className="Form__Legend">Please enter 4 digit OTP sent on your number:</p>
            </header>
            {error ? <p className="Alert Alert--error">{error}.</p> : null}
            <div className="Form__Item">
              <input
                type="number"
                className="Form__Input"
                name="otp"
                required="required"
                placeholder="OTP"
                aria-label="OTP"
                autoFocus=""
                value={otp}
                maxLength="4"
                onChange={(e) => {
                  setOtp(e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
                  // error ? setError("") : null;
                }}
                onInput={(e) => {
                  if (e.target.value.length > 4) e.target.value = e.target.value.slice(0, 4);
                }}
              ></input>
              <Timer resendOTP={onPhoneSubmit} />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <button
                // type="submit"
                label="Back"
                className="Form__Submit Button Button--secondary Button--full"
                // style={{ display: "flex", flex: 0.48, justifyContent: "center" }}
                style={{ flex: 0.48, justifyContent: "center" }}
                onClick={() => {
                  setAppUiState(1);
                  setError("");
                }}
              >
                Back
              </button>
              <button
                type="submit"
                label="Proceed"
                disabled={otp.length == 4 ? false : true}
                className="Form__Submit Button Button--primary Button--full"
                style={{ display: "flex", flex: 0.48, justifyContent: "center" }}
              >
                {buttonLoading ? (
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </form>
        </div>
      </Layout>
    );
  }
};

export default LoginPage;
