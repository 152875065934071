const NatLogo = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 522.17 249.3">
      <g>
        <path d="m76.85,176.55c-2.88,0-4.67-1.36-5.37-4.09-2.14-8.19-3.29-15.63-3.46-22.32-.17-6.7-.25-11.45-.25-14.26,0-14.23,1.03-32.16,3.09-53.78.16-1.64.37-3.4.62-5.29.12-.76.18-1.32.18-1.7,0-.54-.1-.82-.31-.82-.37,0-.97.76-1.79,2.27-3.87,7.05-7.28,14.63-10.24,22.73-3.7,9.99-6.68,19.91-8.92,29.75-2.24,9.85-3.36,17.48-3.36,22.89,0,.84-.03,1.51-.09,2.02-.06.5-.09.96-.09,1.39-.12,2.85-.66,4.76-1.6,5.73-.95.96-2.12,1.45-3.52,1.45-2.88,0-4.67-1.49-5.37-4.47-1.52-6.55-2.34-13.83-2.44-21.85-.1-8.02-.16-13.62-.16-16.81,0-9.66.19-19.35.56-29.1.49-12.97.99-23,1.48-30.1.12-1.8.63-3.15,1.51-4.03.88-.88,1.98-1.46,3.3-1.73,1.32-.27,2.65-.41,4.01-.41,1.19,0,2.35.09,3.49.28,1.13.19,2.06.65,2.78,1.39.72.73,1.08,1.92,1.08,3.56,0,1.76-.12,3.82-.37,6.17-1.4,13.44-2.57,28.61-3.52,45.53,4.15-16.12,8.85-28.99,14.07-38.6,5.47-10.08,11.05-15.11,16.72-15.11,2.96,0,5.22.74,6.76,2.24,1.54,1.49,2.47,3.3,2.78,5.41.31,2.12.46,4,.46,5.64,0,1.8-.12,3.78-.37,5.92-1.81,15.66-3.38,31.2-4.72,46.63-1.34,15.43-2.01,31.05-2.01,46.88,0,2.64-.47,4.41-1.42,5.29-.95.88-2.12,1.32-3.52,1.32Z" />
        <path d="m135.11,150.61c-8.23,0-12.86-8.59-13.89-25.76-1.69,5.84-3.54,10.64-5.55,14.42-3.5,6.47-7.73,9.7-12.71,9.7-2.76,0-4.9-.72-6.42-2.17s-2.6-3.31-3.24-5.57c-.64-2.27-.96-4.58-.96-6.93,0-3.19.23-6.71.68-10.55.45-3.84,1.18-7.64,2.19-11.4,1.01-3.76,2.35-7.2,4.04-10.33,1.69-3.13,3.77-5.64,6.26-7.56,2.49-1.91,5.42-2.87,8.79-2.87s5.96,1.53,8.27,4.6c.29-1.22.72-2.22,1.3-3.02,1.4-1.89,3.95-2.83,7.65-2.83,2.59,0,4.43.38,5.52,1.13,1.09.76,1.63,2,1.63,3.72,0,.76-.06,1.6-.18,2.52-.21,1.39-.54,4.35-.99,8.88-1.03,10.29-1.54,18.89-1.54,25.82,0,1.22.05,3.12.15,5.7.1,2.58.67,3.87,1.7,3.87,1.89,0,4.61-4.09,8.15-12.28,2.59-6.05,4.89-12.39,6.91-19.02.37-1.18,1.17-1.76,2.41-1.76,1.03,0,1.81.36,2.35,1.07.33.46.49.99.49,1.57,0,.67-1.23,4.62-3.7,11.84-6.21,18.14-12.65,27.21-19.32,27.21Zm-27.15-12.91c1.07,0,2.37-1.6,3.89-4.79,1.52-3.19,3.01-6.94,4.48-11.24,1.46-4.3,2.69-8.3,3.7-12,1.01-3.7,1.55-6.21,1.63-7.56-.29-.5-.76-.97-1.42-1.41-.66-.44-1.36-.66-2.1-.66-3.66,0-6.64,3.8-8.95,11.4-2.06,6.8-3.09,14.21-3.09,22.23,0,2.69.62,4.03,1.85,4.03Z" />
        <path d="m165.84,151.87c-2.63,0-4.77-.82-6.42-2.45-3.05-2.98-5-7.93-5.86-14.86-.58-4.45-.86-9.42-.86-14.92,0-7.35.43-17.82,1.3-31.42-1.81-.09-3.58-.21-5.31-.38-.82-.08-1.36-.58-1.61-1.48-.25-.9-.37-2.11-.37-3.62,0-.71.06-1.39.18-2.05.12-.65.53-1,1.23-1.04.58,0,1.21.04,1.91.13,1.48.21,2.98.4,4.5.57.12-2.35.31-4.79.56-7.3.12-1.64.64-2.87,1.54-3.72,1.4-1.3,3.83-1.95,7.28-1.95,1.15,0,2.26.09,3.33.28,1.07.19,1.95.62,2.62,1.29.68.67,1.02,1.76,1.02,3.28,0,1.6-.14,3.49-.43,5.67-.16,1.05-.31,2.22-.43,3.52,1.44.04,3.78.06,7.03.06,1.48,0,2.22.82,2.22,2.45,0,1.47-.43,2.39-1.3,2.77-1.77.76-4.65,1.26-8.64,1.51-1.65,19.1-2.47,34.19-2.47,45.28,0,6.51.53,9.76,1.6,9.76,1.85,0,4.5-4.24,7.96-12.72,3-7.39,5.37-14,7.1-19.84.37-1.18,1.17-1.76,2.41-1.76,1.03,0,1.81.36,2.35,1.07.33.46.49.99.49,1.57,0,.55-1.03,3.95-3.09,10.2-6.58,20.07-13.21,30.1-19.87,30.1Z" />
        <path d="m240.14,114.9c4.73-.13,10.26-.19,16.6-.19h4.81c.62-17.51,1.87-38.69,3.76-63.54.29-3.48,1.97-5.54,5.06-6.17,1.36-.25,2.74-.38,4.14-.38,1.23,0,2.44.09,3.61.28,1.17.19,2.14.67,2.9,1.45.76.78,1.14,2.05,1.14,3.81,0,2.18-.14,4.64-.43,7.37-2.1,19.86-3.85,39.15-5.24,57.87,1.69.21,3.29.48,4.81.82,1.77.42,2.65,1.53,2.65,3.34,0,.88-.29,1.6-.86,2.17-.58.57-1.73.85-3.45.85h-3.64c-1.03,15.83-1.54,31.36-1.54,46.6,0,2.94-.47,4.9-1.42,5.89-.95.99-2.12,1.48-3.52,1.48-2.88,0-4.67-1.53-5.37-4.6-1.89-8.31-2.86-16.12-2.9-23.43-.04-7.3-.06-11.61-.06-12.91,0-3.91.04-8.1.12-12.6-7.08.34-14.3.78-21.66,1.32-.58,13.01-.86,24.56-.86,34.63,0,2.94-.47,4.9-1.42,5.89-.95.99-2.12,1.48-3.52,1.48-2.92,0-4.71-1.51-5.37-4.53-1.48-6.76-2.26-14.32-2.34-22.67l-.19-13.6c-2.14.21-3.62.31-4.44.31-.99,0-1.7-.22-2.13-.66-.43-.44-.71-1.01-.83-1.7-.12-.69-.18-1.39-.18-2.11,0-1.26.23-2.43.68-3.53.37-.84,2.67-1.53,6.91-2.08.12-18.68.8-36.92,2.04-54.72.25-3.31,1.85-5.29,4.81-5.92,1.27-.25,2.59-.38,3.95-.38,1.19,0,2.36.09,3.49.28s2.06.66,2.78,1.42c.72.76,1.08,1.97,1.08,3.65,0,2.52-.12,4.91-.37,7.18-1.44,13.69-2.63,29.56-3.58,47.61" />
        <path d="m329.5,150.61c-8.23,0-12.86-8.59-13.89-25.76-1.69,5.84-3.54,10.64-5.55,14.42-3.5,6.47-7.73,9.7-12.71,9.7-2.76,0-4.9-.72-6.42-2.17-1.52-1.45-2.6-3.31-3.24-5.57-.64-2.27-.96-4.58-.96-6.93,0-3.19.23-6.71.68-10.55.45-3.84,1.18-7.64,2.19-11.4,1.01-3.76,2.35-7.2,4.04-10.33,1.69-3.13,3.77-5.64,6.26-7.56,2.49-1.91,5.42-2.87,8.79-2.87s5.96,1.53,8.27,4.6c.29-1.22.72-2.22,1.3-3.02,1.4-1.89,3.95-2.83,7.65-2.83,2.59,0,4.43.38,5.52,1.13,1.09.76,1.63,2,1.63,3.72,0,.76-.06,1.6-.18,2.52-.21,1.39-.54,4.35-.99,8.88-1.03,10.29-1.54,18.89-1.54,25.82,0,1.22.05,3.12.15,5.7.1,2.58.67,3.87,1.7,3.87,1.89,0,4.61-4.09,8.15-12.28,2.59-6.05,4.89-12.39,6.91-19.02.37-1.18,1.17-1.76,2.41-1.76,1.03,0,1.81.36,2.35,1.07.33.46.49.99.49,1.57,0,.67-1.23,4.62-3.7,11.84-6.21,18.14-12.65,27.21-19.32,27.21Zm-27.15-12.91c1.07,0,2.37-1.6,3.89-4.79,1.52-3.19,3.01-6.94,4.48-11.24,1.46-4.3,2.69-8.3,3.7-12,1.01-3.7,1.55-6.21,1.63-7.56-.29-.5-.76-.97-1.42-1.41-.66-.44-1.36-.66-2.1-.66-3.66,0-6.64,3.8-8.95,11.4-2.06,6.8-3.09,14.21-3.09,22.23,0,2.69.62,4.03,1.85,4.03Z" />
        <path d="m355.11,150.61c-2.92,0-4.71-1.36-5.37-4.09-1.73-6.93-2.59-18.79-2.59-35.58,0-8.86.22-18.5.65-28.94.43-10.43.9-18.92,1.39-25.47.12-1.64.63-2.87,1.51-3.68.88-.82,1.99-1.34,3.3-1.57,1.32-.23,2.65-.35,4.01-.35,1.19,0,2.36.09,3.49.25,1.13.17,2.06.59,2.78,1.26.72.67,1.08,1.76,1.08,3.27,0,1.6-.12,3.49-.37,5.67-1.85,16.25-3.25,34.26-4.2,54.03l1.42-4.28c1.19-3.74,2.64-7.17,4.35-10.3,1.71-3.13,3.7-5.66,5.99-7.59,2.28-1.93,4.93-2.89,7.93-2.89,2.51,0,4.65,1.18,6.42,3.56,1.77,2.37,2.65,5.89,2.65,10.55,0,3.74-.27,7.68-.8,11.84-1.11,8.31-3.17,15.45-6.17,21.41,5.92-3.15,10.59-8.71,14.01-16.69,1.36-3.28,2.55-6.72,3.58-10.33.33-1.18,1.11-1.76,2.35-1.76,1.03,0,1.81.36,2.35,1.07.33.46.49.99.49,1.57,0,.42-.7,2.81-2.1,7.18-4.81,15.66-13.88,24.92-27.21,27.77-2.55,2.23-5.43,3.34-8.64,3.34-2.18,0-3.89-.72-5.12-2.17-1.23-1.45-1.85-3.14-1.85-5.07,0-2.27.97-3.4,2.9-3.4.9,0,2.14.3,3.7.88,2.14-1.8,3.97-5.38,5.49-10.71,2.1-7.18,3.15-14.8,3.15-22.86,0-1.18-.16-2.14-.49-2.9s-.8-1.13-1.42-1.13c-1.07,0-2.37,1.66-3.89,4.97-2.96,6.42-5.68,14.44-8.15,24.06-.82,3.36-1.34,6.17-1.54,8.44-.12,1.68-.38,3.87-.77,6.58-.39,2.71-1.82,4.06-4.29,4.06Z" />
        <path d="m413.98,150.61c-5.22,0-8.99-3.46-11.29-10.39-1.81-5.46-2.71-12.32-2.71-20.59,0-15.07.92-23.89,2.78-26.45,1.36-1.89,3.89-2.83,7.59-2.83,2.63,0,4.48.38,5.55,1.13,1.07.76,1.6,2,1.6,3.72,0,.76-.06,1.6-.18,2.52-.17,1.22-.44,3.77-.83,7.65-.39,3.88-.76,8.29-1.11,13.22-.35,4.93-.53,9.41-.53,13.45,0,6.63.6,9.95,1.79,9.95,1.93,0,4.67-4.09,8.21-12.28,2.84-6.55,5.14-12.89,6.91-19.02.33-1.18,1.11-1.76,2.35-1.76,1.03,0,1.81.36,2.35,1.07.33.46.49,1.01.49,1.64s-1.21,4.56-3.64,11.78c-6.21,18.14-12.65,27.21-19.32,27.21Zm-3.27-63.86c-1.03,0-2.03-.09-2.99-.28-.97-.19-1.76-.68-2.37-1.48-.62-.8-.93-2.06-.93-3.78,0-2.18.34-3.81,1.02-4.88.68-1.07,1.61-1.78,2.78-2.14,1.17-.35,2.46-.53,3.86-.53,1.03,0,2.03.09,2.99.28.97.19,1.76.68,2.37,1.48.62.8.93,2.06.93,3.78,0,5.04-2.55,7.56-7.65,7.56Z" />
        <path d="m444.71,151.87c-2.63,0-4.77-.82-6.42-2.45-3.05-2.98-5-7.93-5.86-14.86-.58-4.45-.86-9.42-.86-14.92,0-7.35.43-17.82,1.3-31.42-1.81-.09-3.58-.21-5.31-.38-.82-.08-1.36-.58-1.61-1.48-.25-.9-.37-2.11-.37-3.62,0-.71.06-1.39.18-2.05.12-.65.53-1,1.23-1.04.58,0,1.21.04,1.91.13,1.48.21,2.98.4,4.5.57.12-2.35.31-4.79.56-7.3.12-1.64.64-2.87,1.54-3.72,1.4-1.3,3.83-1.95,7.28-1.95,1.15,0,2.26.09,3.33.28,1.07.19,1.95.62,2.62,1.29.68.67,1.02,1.76,1.02,3.28,0,1.6-.14,3.49-.43,5.67-.16,1.05-.31,2.22-.43,3.52,1.44.04,3.78.06,7.03.06,1.48,0,2.22.82,2.22,2.45,0,1.47-.43,2.39-1.3,2.77-1.77.76-4.65,1.26-8.64,1.51-1.65,19.1-2.47,34.19-2.47,45.28,0,6.51.53,9.76,1.6,9.76,1.85,0,4.5-4.24,7.96-12.72,3-7.39,5.37-14,7.1-19.84.37-1.18,1.17-1.76,2.41-1.76,1.03,0,1.81.36,2.35,1.07.33.46.49.99.49,1.57,0,.55-1.03,3.95-3.09,10.2-6.58,20.07-13.21,30.1-19.87,30.1Z" />
      </g>
      <g>
        <path d="m478.06,52.44v1.92h-5.43v14.21h-2.22v-14.21h-5.43v-1.92h13.09Z" />
        <path d="m480,52.44h3.13l4.63,13.63,4.6-13.63h3.09v16.13h-2.08v-9.52c0-.33,0-.88.02-1.64.02-.76.02-1.58.02-2.45l-4.6,13.6h-2.16l-4.63-13.6v.5c0,.4,0,1,.03,1.81.02.81.03,1.4.03,1.78v9.52h-2.09v-16.13Z" />
      </g>
      <g>
        <path d="m101.23,185.72v2.58h-14.72v9.76h14.26v2.58h-14.26v12.34h-2.58v-27.25h17.29Z" />
        <path d="m115.34,185.72c5.74,0,10.41,4.19,10.41,9.93,0,5.11-3.72,9-8.59,9.78l9.25,7.55h-3.74l-9.08-7.4h-4.03v7.4h-2.58v-27.25h8.36Zm-5.79,2.65v14.63h5.79c4.34,0,7.83-3.01,7.83-7.35s-3.5-7.35-7.83-7.35h-5.79v.07Z" />
        <path d="m149.21,185.72v2.58h-14.72v9.76h14.26v2.58h-14.26v9.76h14.72v2.58h-17.29v-27.25h17.29Z" />
        <path d="m170.3,187.28l-1.33,2.21c-1.89-1.07-3.65-1.78-5.88-1.78-2.79,0-5.43,1.6-5.43,4.4,0,3.62,3.67,4.55,6.24,5.32,3.8,1.06,7.83,3.26,8.05,8.02,0,4.08-3.25,8.19-9.04,8.19-2.53,0-5.59-.74-8.48-2.67l1.39-2.36c2.07,1.32,4.51,2.16,6.99,2.22h.02c3.79,0,6.32-2.47,6.32-5.38-.06-3.3-3.81-4.9-6.82-5.69-3.46-.92-7.46-2.96-7.46-7.65,0-3.96,3.68-7.05,8.25-7.05,3.14,0,5.23,1.07,7.19,2.22Z" />
        <path d="m180.31,185.72v11.62h15.68v-11.62h2.58v27.25h-2.58v-13.05h-15.68v13.05h-2.58v-27.25h2.58Z" />
        <path d="m229.02,185.72l9.93,27.25h-2.74l-2.7-7.4h-12.17l-2.7,7.4h-2.73l9.93-27.25h3.17Zm-6.74,17.28h10.3l-5.15-14.13-5.15,14.13Z" />
        <path d="m241.78,185.72l8.25,16.7,8.25-16.7h2.87l-9.84,19.91v7.34h-2.58v-7.34l-9.84-19.91h2.87Z" />
        <path d="m269.52,185.73v18.26c0,3.9,3.23,7.07,7.29,7.07s7.29-3.17,7.29-7.07v-18.26h2.58v18.26c0,5.33-4.45,9.65-9.87,9.65s-9.86-4.31-9.86-9.65v-18.26h2.58Z" />
        <path d="m301.34,185.72c5.74,0,10.41,4.19,10.41,9.93,0,5.11-3.72,9-8.59,9.78l9.25,7.55h-3.74l-9.08-7.4h-4.03v7.4h-2.58v-27.25h8.36Zm-5.79,2.65v14.63h5.79c4.34,0,7.83-3.01,7.83-7.35s-3.5-7.35-7.83-7.35h-5.79v.07Z" />
        <path d="m318.29,185.72l9.19,25.23,9.19-25.23h2.74l-9.93,27.25h-4.01l-9.93-27.25h2.74Z" />
        <path d="m362.19,185.72v2.58h-14.72v9.76h14.26v2.58h-14.26v9.76h14.72v2.58h-17.29v-27.25h17.29Z" />
        <path d="m376.28,185.72c7.51,0,13.62,6.12,13.62,13.63s-6.12,13.63-13.62,13.63h-7.82v-27.25h7.82Zm-5.24,24.67h5.24c6.12,0,11.05-4.93,11.05-11.05s-4.93-11.05-11.05-11.05h-5.24v22.09Z" />
        <path d="m407.71,185.72l9.93,27.25h-2.74l-2.7-7.4h-12.17l-2.7,7.4h-2.73l9.93-27.25h3.17Zm-6.74,17.28h10.3l-5.15-14.13-5.15,14.13Z" />
      </g>
      <rect x="33.05" y="197.19" width="42.08" height="1.22" />
      <rect x="425.71" y="197.19" width="42.08" height="1.22" />
    </svg>
  );
};

export default NatLogo;
