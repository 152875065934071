import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CrossIcon } from "../../asset/img/Cross.svg";
import CustomRating from "./CustomRating";
import Dropzone from "./Dropzone";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import swal from "sweetalert";
import { feedbackAPI } from "../../helpers/api";
import Layout from "../../components/Layout";
import MainHeader from "../MainHeader/index";

import {
  fetchOrderDetailsForRatePage,
  getProductRatingParameters,
  postFeedbackWithParametersFormData,
  postOverAllItemFeedback,
} from "../../store/order/actions";
import { FaSpinner } from "react-icons/fa";
// import 'antd/dist/antd.css';
import "../../asset/scss/base.scss";
import "../../asset/scss/form.scss";
import "./scss/styles.scss";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

export default function Rating(props) {
  const scrollRef = useRef(null);

  const {
    match: {
      params: { orderName, productId, variantId, customerPhone },
    },
  } = props;
  // const { history } = props;
  const [ratingParameters, setRatingParameters] = useState({});
  const [files, setFiles] = useState([]);
  const fileObject = [];
  const [fileObjectUrls, setFileObjectUrls] = useState([]);
  const [placeOrderButtonLoading, setPlaceOrderButtonLoading] = useState(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const [feedbackError, setFeedbackError] = useState(false);
  const [state, setState] = useState({
    reviewTitle: "",
    reviewContent: "",
    generalRating: "",
    cleansingRating: "",
    fragranceRating: "",
    shelfLifeRating: "",
  });

  // const rateButtonDisabled = () => {
  //   return !(state.reviewTitle && state.reviewContent);
  // };

  // const isRateButtonDisabled = useMemo(rateButtonDisabled, [state]);
  const orderDetails = useSelector((state) => state.order.orderDetailsRatePage);
  const productRatingParameters = useSelector((state) => state.order.productRatingParameters);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrderDetailsForRatePage(orderName));
  }, [dispatch, orderName]);

  useEffect(() => {
    dispatch(getProductRatingParameters(variantId));
  }, [dispatch, variantId]);

  useEffect(() => {
    if (orderDetails) {
      window.scrollTo(0, scrollRef.current.offsetTop - 100);
    }
  }, [orderDetails]);

  const onUpload = (files) => {
    setFiles(files[0]);
    // setFileObjectUrls([URL.createObjectURL(files[0])]);
    files.map((file) => {
      fileObject.push([URL.createObjectURL(file)]);
    });
    setFileObjectUrls(fileObject);
  };

  const removeFile = () => {
    setFileObjectUrls([]);
    fileObject = [];
  };

  const onReviewTitleChange = ({ target: { value } }) => {
    setState((state) => ({
      ...state,
      reviewTitle: value,
    }));
  };

  const onReviewContentChange = ({ target: { value } }) => {
    setState((state) => ({
      ...state,
      reviewContent: value,
    }));
  };

  const onGeneralRatingChange = (value) => {
    try {
      window.Moengage.track_event("Rated product", {
        ratings: value,
        product_id: productId,
        variant_id: variantId,
        referrer_section: "Review Page",
      });
    } catch (error) {
      console.log(error);
    }
    setState((state) => ({
      ...state,
      generalRating: value,
    }));
    dispatch(
      postOverAllItemFeedback({
        order_name: orderName,
        product_id: productId,
        variant_id: variantId,
        overall_rating: value,
      })
    );
  };

  const onFeedbackParamChange = (param_name, score) => {
    setRatingParameters((state) => ({
      ...state,
      [param_name]: score,
    }));
    const formData = new FormData();
    formData.append("order_name", orderName);
    formData.append("product_id", productId);
    formData.append("variant_id", variantId);
    formData.append("param_feedback", JSON.stringify([{ param_name, score }]));
    const payload = {
      order_name: orderName,
      product_id: productId,
      variant_id: variantId,
      param_feedback: JSON.stringify([{ param_name, score }]),
    };
    const changePage = false;

    dispatch(postFeedbackWithParametersFormData(formData, changePage, customerPhone));
  };

  const onRateClick = () => {
    try {
      window.Moengage.track_event("Submitted Review", {
        product_id: productId,
        variant_id: variantId,
        product_name: product_name
          .split("<br>")[0]
          .replace("<strong>", "")
          .replace("</strong>", "")
          .replace("<i>", "")
          .replace("</i>", ""),
        contains_title: state.reviewTitle.length > 0 ? true : false,
        contains_review: state.reviewContent.length > 0 ? true : false,
        contains_media: files.length > 0 ? true : false,
        rating: state.generalRating,
      });
    } catch (error) {
      console.log(error);
    }
    setPlaceOrderButtonLoading(true);
    const { reviewTitle, reviewContent, generalRating } = state;
    const data = {
      order_name: orderName,
      product_id: productId,
      variant_id: variantId,
      review_title: reviewTitle,
      review_details: reviewContent,
      image: files,
      // param_feedback: Object.entries(ratingParameters).map(
      //   ([param_name, score]) => ({
      //     param_name,
      //     score,
      //   })
      // ),
    };
    const formData = new FormData();
    formData.append("order_name", orderName);
    formData.append("product_id", productId);
    formData.append("variant_id", variantId);
    formData.append("review_title", reviewTitle);
    formData.append("review_details", reviewContent);
    formData.append("image", files);
    const changePage = true;

    // dispatch(postFeedbackWithParametersFormData(formData, changePage, customerPhone, orderName, history));

    fetch(`${feedbackAPI}/v1/checkout/order/item/param/feedback`, {
      method: "POST",
      body: formData,
    })
      .then((result) => {
        if (result.status == 200) {
          setFeedbackSuccess(true);
        } else {
          setFeedbackError(true);
        }
      })
      .catch((error) => {
        setFeedbackError(true);
      });
  };

  useEffect(() => {
    const currentUrl = window.location.origin;

    if (feedbackSuccess) {
      swal({
        title: "Thank you.",
        text: "Your feedback was submitted successfully!",
        button: "Back to Your Orders",
        // icon: { TickIcon },
      }).then((value) => {
        window.location.assign(`${currentUrl}/pages/order-tracking`);
      });
    } else if (feedbackError) {
      swal({
        title: "Oops!",
        text: "Something went wrong. Please try again!",
        // icon: "error",
      }).then((value) => {
        window.location.reload();
      });
    }
  }, [feedbackSuccess, feedbackError]);

  // if (!orderDetails) {
  //   return null;
  // }

  const product = orderDetails?.order_items
    ? orderDetails.order_items.find(({ product_id }) => product_id === productId)
    : null;

  if (!product) {
    // 404 page
    return null;
  }

  const { product_name, featured_image, overall_rating } = product;

  const html = document.createElement("html");
  // html.innerHTML = product_name;
  const body = html.getElementsByTagName("body")[0];
  const productNameFirstRow = body?.firstChild?.nodeValue;

  return (
    <div>
      <Layout>
        <MainHeader />
        {orderDetails ? (
          <div ref={scrollRef} className="mt-6">
            <div className="rate-component-container">
              <div className="rate-your-experience-container">
                <h2 className="rate-your-experience">Rate Your Experience</h2>
                <div className="order-item-info">
                  <div className="order-item-image-container">
                    <img className="order-item-image" src={featured_image} alt={productNameFirstRow} />
                  </div>
                  <div className="order-item-description-rate">
                    <div className="order-item-name" dangerouslySetInnerHTML={{ __html: product_name }} />
                    <div className="rate-container">
                      <CustomRating
                        size="large"
                        value={state.generalRating == "" ? overall_rating : state.generalRating}
                        onChange={onGeneralRatingChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {productRatingParameters.length ? (
                <div className="general-rate-container">
                  {[...productRatingParameters]
                    .sort((a, b) => a.display_sequence - b.display_sequence)
                    .map(({ id, rating_param_name }) => (
                      <div key={id} className="general-rate-row">
                        <div className="rate-label">
                          <span>{rating_param_name}</span>
                        </div>
                        <div className="rate-container">
                          <CustomRating
                            size="large"
                            value={ratingParameters?.[rating_param_name] || 0}
                            onChange={(value) => {
                              onFeedbackParamChange(rating_param_name, value);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              ) : null}
              <div className="review-container">
                <div className="review-header">
                  <h3>Write a review</h3>
                </div>
                <div className="review-row">
                  <div className="review-label">
                    <span>Review Title</span>
                  </div>
                  <input
                    name="reviewTitle"
                    className="review-field review-title-field"
                    placeholder="Enter review title here"
                    value={state.reviewTitle}
                    onChange={onReviewTitleChange}
                  />
                </div>
                <div className="review-row">
                  <div className="review-label">
                    <span>Your Review</span>
                  </div>
                  <textarea
                    name="reviewContent"
                    className="review-field your-review-field"
                    placeholder="Write your thoughts and experience with this product"
                    // autoSize
                    value={state.reviewContent}
                    onChange={onReviewContentChange}
                  />
                </div>
              </div>
              <div className="dropzone-container">
                <Dropzone onUpload={onUpload} />
                <div className="uploaded-images-container">
                  {fileObjectUrls.map((url, key) => (
                    <div className="uploaded-image-container">
                      <div className="cross-icon-container" onClick={() => removeFile(url)}>
                        <CrossIcon />
                      </div>
                      <img src={url} alt={"uploaded_image_" + key} className="uploaded-image" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="rate-button-container">
              <button
                onClick={onRateClick}
                className={`rate-button rate-button-enabled`}

                // className={`rate-button rate-button-${isRateButtonDisabled ? "disabled" : "enabled"}`}
                // disabled={isRateButtonDisabled}
              >
                {placeOrderButtonLoading ? (
                  <FaSpinner icon="spinner" className="spinner" />
                ) : (
                  <span>RATE PRODUCT</span>
                )}
              </button>
            </div>
          </div>
        ) : (
          // <ClipLoader color="#5c5c5c" loading={loading} css={override} size={50} />
          <div className="flex items-center justify-center h-[calc(100vh-48px)]">
            <ClipLoader color="#5c5c5c" css={override} size={50} className="mt-6" />
          </div>
        )}
      </Layout>
    </div>
  );
}
