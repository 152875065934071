import React from "react";
import { BrowserRouter as Router, Switch, useHistory, Route } from "react-router-dom";
import OrdersPage from "../pages/ordersPage";
import LoginPage from "../pages/accountLoginPage";

import { useLocation } from "react-router-dom";
import ProductRate from "../components/rating";
// import OrderDetails from "../components/orderDetails";
import HashRoutes from "./HashRoutes";
export default function Routes(props) {
  let history = useHistory(props);

  return (
    <>
      <Router history={history}>
        <Route path="/" exact component={OrdersPage} />
        <Route path="/account/login" component={LoginPage} />
        <Route path="/pages/order-tracking" exact component={OrdersPage} />
        {/* <Route path="#/:orderName/rate-product/:productId/:variantId" exact component={ProductRate} /> */}
        <Switch>
          <Route path="/pages/order-tracking/track" exact component={HashRoutes} />
          {/* <Route path="/checkout/order/track/:customerPhone/:orderName" exact component={OrderDetails} /> */}
        </Switch>
      </Router>
    </>
  );
}
