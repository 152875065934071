import React from "react";

import { ReactComponent as StarEmptyMediumIcon } from "../../asset/img/StarEmptyMedium.svg";
import { ReactComponent as StarFilledMediumIcon } from "../../asset/img/StarFilledMedium.svg";

import "./scss/customRating.scss";

const CustomRating = (props) => {
  const { max = 5, value = 0, onChange, size = "medium", filledIcon, emptyIcon } = props;

  return (
    <div className="custom-rating-container">
      {[...Array(max).keys()].map((count) => (
        <div
          key={count + 1}
          className={size == "small" ? "pl-1" : "star-container"}
          onClick={(event) => {
            event.preventDefault();
            if (typeof onChange === "function") {
              if (value === count + 1) {
                onChange(0);
              } else {
                onChange(count + 1);
              }
            }
          }}
        >
          {size == "small" ? (
            <div className={`star-size-container `}>
              {count + 1 <= (Number(value) || 0)
                ? filledIcon || <StarFilledMediumIcon className="w-5" />
                : emptyIcon || <StarEmptyMediumIcon className="w-5" />}
            </div>
          ) : (
            <div className={`star-size-container star-large star-${size}`}>
              {count + 1 <= (Number(value) || 0)
                ? filledIcon || <StarFilledMediumIcon />
                : emptyIcon || <StarEmptyMediumIcon />}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CustomRating;
