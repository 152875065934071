import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

// import { composeWithDevTools } from "redux-devtools-extension";

import { orderListingReducer } from "./order/reducer";

const rootReducer = combineReducers({
  order: orderListingReducer,
});

// export default function configureStore() {
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
