export const getRootNavigation = `
query {
  renderNavigation(
    navigationIdOrSlug: "main-navigation"
    type: TREE
    menuOnly: false
  ) {
    id
    title
    path
    related {
      ... on NavigationItemRelatedData {
        attributes {
          ... on Category {
            publishedAt
            collections {
              data {
                attributes {
                  title
                  superScript
                }
              }
            }
            concerns {
              data {
                attributes {
                  title
                  url
                  displayTitle
                }
              }
            }
            tissueTypes {
              data {
                attributes {
                  title
                  url
                  displayTitle
                }
              }
            }
            habits {
              data {
                attributes {
                  title
                  url
                }
              }
            }
            navigationImage {
              data {
                attributes {
                  url
                }
              }
            }
          }
          ... on Hompage {
            navigationImage {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
    items {
      id
      title
      path
      related {
        ... on NavigationItemRelatedData {
          attributes {
            ... on Collection {
              publishedAt
            }
          }
        }
      }
    }
  }
}
`;
