import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const ErrorMessage = ({ message, message_type }) => {
  let container_message_class = "info";
  let message_class = "info-message";
  if (message_type && message_type.toLowerCase() == "positive") {
    container_message_class = "positive";
    message_class = "positive-message";
  } else if (message_type && message_type.toLowerCase() == "negative") {
    container_message_class = "negative";
    message_class = "negative-message";
  }
  return (
    <div>
      {message ? (
        <div className={`w-full error-message-container ${container_message_class}`}>
          <span className={`flex justify-center w-full error-message ${message_class}`}>{message}</span>
        </div>
      ) : null}
    </div>
  );
};
ErrorMessage.propTypes = {
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  message: "",
};

export default ErrorMessage;
