import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactStars from "react-rating-stars-component";
import Cookies from "js-cookie";

// import { Rating } from '@material-ui/lab';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import { Button } from 'antd';

// import OrderName from "../orderCart/components/OrderName";
// import OrderStatus from "../orderCart/components/OrderStatus";
import ErrorMessage from "../orderCart/components/ErrorMessage";
import UsageInstructions from "../orderCart/components/UsageInstructions";
import ShippingAddress from "./components/ShippingAddress";
import BillDetails from "./components/BillDetails";
import { ReactComponent as IncreaseIcon } from "../../asset/img/Plus.svg";
import { ReactComponent as DecreaseIcon } from "../../asset/img/Minus.svg";
import { ReactComponent as EllipseIcon } from "../../asset/img/Ellipse.svg";

import {
  increaseOrderItemQuantity,
  decreaseOrderItemQuantity,
  addToCartOrderedProduct,
  postOverAllFeedback,
} from "../../store/order/actions";

import { useQuery } from "../../helpers";

import "../../asset/scss/base.scss";
import "../../asset/scss/form.scss";
import "./styles.scss";
import CartItemAddToCart from "../orderCart/components/OrderProducts/_builder/ItemAddToCart";
import CustomRating from "../rating/CustomRating";

export default function OrderDetails(props) {
  const { history } = props;
  const [overallRating, setOverallRating] = useState(0);

  const dispatch = useDispatch();

  const userOrders = useSelector((state) => state.order);
  const { customer_id, customer_phone, orders, currentOrder: currentOrderName } = userOrders;

  const location = useQuery();

  let cartId = location.get("cart_id");
  if (cartId && cartId.includes("cart_token")) {
    window.location.href = `?cart_id=${cartId.split("=")[1]}`;
  }

  let cartID = Cookies.get("cust_cart");

  const currentOrder = orders.find((order) => order?.order_name === currentOrderName);

  if (!currentOrder) {
    return null;
  }

  const {
    error_message,
    // tracking_info,
    // tracking_info: { primary_status, primary_date, secondary_status, secondary_date },
    order_items,
    shipping_address,
    item_total_price,
    discount,
    shipping,
    sub_total_price,
    payment_charges,
    promotion_discount_amount,
    redeemed_nat_cash,
    additional_off_value,
  } = currentOrder;

  const handleOk = () => {
    window.location.href = "https://nathabit.in";
  };

  return (
    <>
      <div className="order-details-container">
        <h4 className="orders-label">{currentOrderName}</h4>

        {/* <OrderName orderName={currentOrderName} /> */}

        {/* <OrderStatus
          primaryStatus={primary_status}
          primaryDate={primary_date}
          secondaryStatus={secondary_status}
          secondaryDate={secondary_date}
        /> */}

        {error_message.message ? (
          <ErrorMessage message={error_message.message} message_type={error_message.message_type} />
        ) : null}

        <div className="order-details-items-container">
          {/* <div className=''>Your Items</div> */}

          {order_items.map((item, idx) => {
            return (
              <div key={idx} className="order-item-container">
                <div className="order-item-sub-container-1">
                  <div className="order-item-image-container">
                    <img className="order-item-image" src={item.featured_image} alt="Product" />
                  </div>
                  <div className="order-item-info-container">
                    <div className="order-name-manufacture">
                      <div className="order-item-info">
                        {item.product_name && (
                          <div
                            className="order-item-name !font-bold"
                            dangerouslySetInnerHTML={{
                              __html: item.product_name,
                            }}
                          />
                        )}
                        {item.variant_name && (
                          <div className="order-item-quantity-container !mb-[6px] ">
                            <span className="order-item-quantity-value">
                              <i>{item.variant_name}</i>
                            </span>
                          </div>
                        )}
                        <div className="order-item-quantity-container">
                          <span className="order-item-quantity-label">Quantity:&nbsp;</span>
                          <span className="order-item-quantity-value">{item.quantity}</span>
                        </div>
                      </div>
                      {item.mfg_message ? (
                        <div className="manufacture-date-info-container">
                          <div className="manufacture-date-icon">
                            <EllipseIcon />
                          </div>
                          <span className="manufacture-date-info">{item.mfg_message}</span>
                        </div>
                      ) : null}
                    </div>
                    <div className="control-buttons-group">
                      {/* {item?.ui?.show_quantity_selector ? (
                        <div className="quantity-control-button-groups">
                          <div
                            className="decrease-icon"
                            onClick={() => {
                              dispatch(decreaseOrderItemQuantity(currentOrderName, item.product_id));
                            }}
                          >
                            <DecreaseIcon />
                          </div>
                          <div>{item.quantity}</div>
                          <div
                            className="increase-icon"
                            onClick={(e) => {
                              dispatch(increaseOrderItemQuantity(currentOrderName, item.product_id));
                            }}
                          >
                            <IncreaseIcon />
                          </div>
                        </div>
                      ) : null} */}

                      {item?.ui?.show_add_to_cart ? (
                        // <button
                        //   className="add-to-cart-button"
                        //   onClick={(e) => {
                        //     dispatch(
                        //       addToCartOrderedProduct(
                        //         item.quantity,
                        //         item.variant_id,
                        //         item.product_id,
                        //         item.product_name,
                        //         cartId,
                        //         cartID
                        //       )
                        //     );
                        //   }}
                        // >
                        //   ADD TO CART-5
                        // </button>
                        <CartItemAddToCart variantId={item.variant_id} />
                      ) : null}
                    </div>
                  </div>
                </div>

                {item.usage_instructions ? <UsageInstructions usageDirections={item.usage_instructions} /> : null}
                {item?.ui?.show_rate_product ? (
                  <div className="product-rate-container">
                    <div className="rating-label">
                      <span>Rate Product</span>
                    </div>
                    <div className="rate-product-link" key={item.product_id}>
                      {/* {item.overall_rating ? (

                        <ReactStars
                          count={5}
                          // onChange={ratingChanged}
                          value={item.overall_rating}
                          size={24}
                          edit={false}
                          isHalf={false}
                          emptyIcon={<i className="far fa-star"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#579F79"
                          color={'#D3D3D3'}
                        />
                      ) : ( */}
                      <CustomRating
                        size={"small"}
                        value={item.overall_rating ? item.overall_rating : overallRating}
                        onChange={(value) => {
                          setOverallRating(value);
                          dispatch(
                            postOverAllFeedback({
                              order_name: currentOrderName,
                              product_id: item.product_id,
                              variant_id: item.variant_id,
                              customer_id: customer_id,
                              overall_rating: value,
                              history: history,
                            })
                          );
                        }}
                      />
                      {/* <ReactStars
                        count={5}
                        value={item.overall_rating ? item.overall_rating : overallRating}
                        size={24}
                        isHalf={false}
                        emptyIcon={<i className="far fa-star"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#579F79"
                        color="#D3D3D3"
                        onChange={(newValue) => {
                          // try {
                          //   window.Moengage.track_event("Rated product", {
                          //     ratings: value,
                          //     product_id: item.product_id,
                          //     variant_id: item.variant_id,
                          //     product_name: item.product_name
                          //       .split("<br>")[0]
                          //       .replace("<strong>", "")
                          //       .replace("</strong>", "")
                          //       .replace("<i>", "")
                          //       .replace("</i>", ""),
                          //     referrer_section: "Order Details",
                          //   });
                          // } catch (error) {
                          //   console.log(error);
                          // }
                          setOverallRating(newValue);
                          dispatch(
                            postOverAllFeedback({
                              order_name: currentOrderName,
                              product_id: item.product_id,
                              variant_id: item.variant_id,
                              customer_id: customer_id,
                              overall_rating: newValue,
                              customerPhone: customer_phone,
                              history: history,
                            })
                          );
                        }}
                      /> */}
                      {/* )} */}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
        <BillDetails
          itemTotalPrice={item_total_price}
          discount={discount}
          shipping={shipping}
          subTotalPrice={sub_total_price}
          payment_charges={payment_charges}
          promotion_discount_amount={promotion_discount_amount}
          redeemed_nat_cash={redeemed_nat_cash}
          additional_off_value={additional_off_value}
        />

        <ShippingAddress shippingAddress={shipping_address} />
      </div>
      <button type="primary" className="continue-shipping-button" onClick={handleOk}>
        CONTINUE SHOPPING
      </button>
    </>
  );
}
