import Cookies from "js-cookie";

const responseChecker = async (response) => {
  let error = "";
  let data = {};
  let statusCode = null;
  if (!response.ok) {
    error = "Something went wrong";
  }
  statusCode = response.status;
  data = await response.json();
  return { statusCode, error, data };
};

const fetchFn = (baseURL, authHeader = null, mode = null, disabledKey = false) => {
  if (!authHeader) {
    let accessToken = Cookies.get("access_token");
    authHeader = accessToken;
  }
  const defaultHeader = {
    Accept: "application/json",
    // "Content-Type": "application/x-www-form-urlencoded",
    "Content-Type": "application/json",
    Authorization: authHeader,
  };

  if (!disabledKey) defaultHeader.key = "nHabit";

  // if (mode) defaultHeader["mode"] = mode;

  const customFetch = (url, method = "GET", body = false, headers = defaultHeader) => {
    const options = {
      method,
      headers,
      // credentials: "include",
    };
    // if (mode) options.mode = mode
    if (body) options.body = JSON.stringify(body);
    return fetch(url, options);
  };
  const get = async (endpoint) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(url, "GET");
    return responseChecker(response);
  };
  const post = async (endpoint, body = {}) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(url, "POST", body);
    return responseChecker(response);
  };
  const put = async (endpoint, body = {}) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(url, "PUT", body);
    return responseChecker(response);
  };
  const patch = async (endpoint, body = {}) => {
    const url = `${baseURL}${endpoint}`;
    const response = await customFetch(url, "PATCH", body);
    return responseChecker(response);
  };
  return {
    get,
    post,
    put,
    patch,
  };
};
export default fetchFn;
