import cookie from "js-cookie";
import { getAuthToken, getCartToken } from "./helpers";

const BASE_URL = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/v2`
  : "https://ecomm.api.nathabit.in/v2";

export const addProductToCart = async (payload) => {
  // if (!cookie.get("cust_cart")) {
  //   await getCartStatus();
  // }

  const res = await fetch(`${BASE_URL}/api/v2/cart/add/cart/item`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      cart: getCartToken(),
      Authorization: getAuthToken(),
    },
  });

  if (!res.ok) {
    const err = new Error("Failed to add product");
    window.NREUM.noticeError(err, {
      cart_token: getCartToken(),
      ...payload,
    });
    throw err;
  }

  const data = await res.json();
  if (data.message === "Cart updated") {
    return data;
  } else {
    const err = new Error("Failed to add product");
    window.NREUM.noticeError(err, {
      cart_token: getCartToken(),
      ...payload,
    });
    throw err;
  }
};

export const removeProductFromCart = async (payload) => {
  if (!cookie.get("cust_cart")) {
    await getCartStatus();
  }

  const res = await fetch(`${BASE_URL}/api/v2/cart/remove/cart/item`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      cart: getCartToken(),
      Authorization: getAuthToken(),
    },
  });

  if (!res.ok) {
    const err = new Error("Failed to remove product");
    window.NREUM.noticeError(err, {
      cart_token: getCartToken(),
      ...payload,
    });
    throw err;
  }

  const data = await res.json();
  if (data.message === "Cart updated") {
    return data;
  } else {
    const err = new Error("Failed to remove product");
    window.NREUM.noticeError(err, {
      cart_token: getCartToken(),
      ...payload,
    });
    throw err;
  }
};

export const getCartStatus = async () => {
  const res = await fetch(`${BASE_URL}/api/v2/cart/item/stock-quantity/status`, {
    credentials: "include",
    headers: {
      Authorization: getAuthToken(),
      cart: getCartToken(),
    },
  });

  if (!res.ok) {
    const err = new Error("Failed to fetch cart details");
    window.NREUM.noticeError(err, {
      cart_token: getCartToken(),
    });
    throw err;
  }

  const data = await res.json();
  return data;
};
