import React from "react";
import { useLocation } from "react-router-dom";

const HeaderCategoryItem = ({ path, title }) => {
  const location = useLocation();
  const pathname = location.pathname;

  const to = `https://nathabit.in${path}?ref_sec=cat_nav|f_top`;

  return (
    <div className="min-w-fit">
      {title === "Home" ? null : (
        <a href={to}>
          {pathname.startsWith(path) ? (
            <p className="text-[13px] font-bold !text-content-0 pb-2 border-b-[4px] !text-center">{title}</p>
          ) : (
            <p className="text-[13px] !text-content-3 pb-2 border-b-[4px] border-b-transparent !text-center">
              {title}
            </p>
          )}
        </a>
      )}
    </div>
  );
};

export default HeaderCategoryItem;
