import CategoryDrawerAccordian from "./CategoryDrawerAccordian";

const AllCategoriesDrawerPageIndex = ({ navigationData }) => {
  return (
    <div className="absolute top-0 left-0 flex flex-col w-full pb-12">
      {navigationData.map((item) => (
        <div key={item.id}>
          <CategoryDrawerAccordian
            key={item.id}
            category={item}
            collectionData={item.related.attributes.collections?.data}
          />
        </div>
      ))}
    </div>
  );
};

export default AllCategoriesDrawerPageIndex;
