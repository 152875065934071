import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import Cookies from "js-cookie";

// import Rating from '@material-ui/lab/Rating';
// import StarBorderIcon from '@material-ui/icons/StarBorder';

import UsageInstructions from "../UsageInstructions";
import { ReactComponent as MinusIcon } from "../../../../asset/img/Minus.svg";
import { ReactComponent as PlusIcon } from "../../../../asset/img/Plus.svg";
import { ReactComponent as IncreaseIcon } from "../../../../asset/img/Plus.svg";
import { ReactComponent as DecreaseIcon } from "../../../../asset/img/Minus.svg";
import { ReactComponent as EllipseIcon } from "../../../../asset/img/Ellipse.svg";
import { FaSpinner } from "react-icons/fa";

import {
  toggleOrderDetails,
  increaseOrderItemQuantity,
  decreaseOrderItemQuantity,
  fetchOrderDetails,
  addToCartOrderedProduct,
  postOverAllFeedback,
} from "../../../../store/order/actions";

import "./styles.scss";
import CartItemAddToCart from "./_builder/ItemAddToCart";
import CustomRating from "../../../rating/CustomRating";

const OrderProducts = (props) => {
  const { customerId, orderName, itemCount, toggled, orderItems, customerPhone, cartId, history } = props;
  const [overallRating, setOverallRating] = useState(0);
  const [loader, setLoader] = useState(false);
  const Orders = useSelector((state) => state.order);
  const { total_items } = Orders;

  const dispatch = useDispatch();
  // let cartID = Cookies.get("cust_cart");

  useEffect(() => {
    dispatch(fetchOrderDetails(orderName, toggled));
  }, []);

  const onToggleOrderDetailsClick = () => {
    dispatch(toggleOrderDetails(orderName));
  };

  const openToggle = (event) => {
    // event.preventDefault();
    // event.stopPropagation();
    if (toggled) {
      setLoader(true);
      onToggleOrderDetailsClick();
    }
  };

  useEffect(() => {
    if (orderItems) {
      setLoader(false);
    }
  }, [orderItems]);

  // const cartAdd = (quantity, variant_id, product_id, product_name, cartId, cartID) => {
  //   dispatch(addToCartOrderedProduct(quantity, variant_id, product_id, product_name, cartId, cartID));
  // };

  // useEffect(() => {
  //   if (total_items) {
  //     document.getElementById("countOfItem").innerHTML = `${total_items}`;
  //     if (total_items.toString().length <= 1) {
  //       document
  //         .getElementById("countOfItem")
  //         .classList.replace("cus-Header__CartCount", "cus-Header__CartCount_left");
  //     } else {
  //       document
  //         .getElementById("countOfItem")
  //         .classList.replace("cus-Header__CartCount_left", "cus-Header__CartCount");
  //     }
  //   }
  // }, [total_items]);

  return (
    <div className="order-items-container" onClick={(event) => openToggle(event)}>
      <div className="order-items-header">
        <div className="order-items-number">
          {itemCount}&nbsp;{`${itemCount > 1 ? "items" : "item"}`}
        </div>
        <div className="toggle-icon-container">
          <div className="toggle-icon" onClick={onToggleOrderDetailsClick}>
            {/* {toggled ? <PlusIcon /> : <MinusIcon />} */}
            {(() => {
              if (loader) {
                return <FaSpinner icon="spinner" className="spinner" />;
              } else {
                if (toggled) {
                  return <PlusIcon />;
                } else {
                  return <MinusIcon />;
                }
              }
            })()}
          </div>
        </div>
      </div>

      <div className="order-items">
        <div className={`${toggled ? "collapsed" : "expanded"}`}>
          {toggled
            ? null
            : orderItems.map((item, idx) => {
                return (
                  <div key={idx} className="order-item-container">
                    <div className="order-item-sub-container-1">
                      <div className="order-item-image-container">
                        <img className="order-item-image" src={item.featured_image} alt="Product" />
                      </div>
                      <div className="order-item-info-container !justify-start gap-1">
                        <div className="order-item-info">
                          {item.product_name && (
                            <div
                              className="order-item-name !font-bold !mb-1"
                              dangerouslySetInnerHTML={{ __html: item.product_name }}
                              // dangerouslySetInnerHTML={{
                              //   __html: item.product_name.split(/<\\?\w+>/)[0],
                              // }}
                            />
                          )}
                          {item.variant_name && (
                            <div className="order-item-quantity-container !mb-[6px]">
                              <span className="order-item-quantity-value">
                                <i>{item.variant_name}</i>
                              </span>
                            </div>
                          )}
                          <div className="order-item-quantity-container">
                            <span className="order-item-quantity-label">Quantity:&nbsp;</span>
                            <span className="order-item-quantity-value">{item.quantity}</span>
                          </div>
                        </div>
                        {item.mfg_message ? (
                          <div className="manufacture-date-info-container">
                            <div className="manufacture-date-icon">
                              <EllipseIcon />
                            </div>
                            <span className="manufacture-date-info">{item.mfg_message}</span>
                          </div>
                        ) : null}
                        <div className="control-buttons-groupr">
                          {/* {item?.ui?.show_quantity_selector ? (
                            <div className="quantity-control-button-groups">
                              <div
                                className="decrease-icon"
                                onClick={() => {
                                  dispatch(decreaseOrderItemQuantity(orderName, item.product_id));
                                }}
                              >
                                <DecreaseIcon />
                              </div>
                              <div>{item.quantity}</div>
                              <div
                                className="increase-icon"
                                onClick={(e) => {
                                  dispatch(increaseOrderItemQuantity(orderName, item.product_id));
                                }}
                              >
                                <IncreaseIcon />
                              </div>
                            </div>
                          ) : null} */}

                          {item?.ui?.show_add_to_cart ? (
                            // <button
                            //   className="add-to-cart-button"
                            //   onClick={() =>
                            //     cartAdd(
                            //       item.quantity,
                            //       item.variant_id,
                            //       item.product_id,
                            //       item.product_name,
                            //       cartId,
                            //       cartID
                            //     )
                            //   }
                            // >
                            //   ADD TO CART-1
                            // </button>
                            <CartItemAddToCart variantId={item.variant_id} />
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="small-resolution-devices">
                      <div className="control-buttons-group">
                        {item?.ui?.show_quantity_selector ? (
                          <div className="quantity-control-button-groups">
                            <div
                              className="decrease-icon"
                              onClick={() => {
                                dispatch(decreaseOrderItemQuantity(orderName, item.product_id));
                              }}
                            >
                              <DecreaseIcon />
                            </div>
                            <div>{item.quantity}</div>

                            <div
                              className="increase-icon"
                              onClick={(e) => {
                                dispatch(increaseOrderItemQuantity(orderName, item.product_id));
                              }}
                            >
                              <IncreaseIcon />
                            </div>
                          </div>
                        ) : null}

                        {item?.ui?.show_add_to_cart ? (
                          // <button
                          //   className="add-to-cart-button"
                          //   onClick={() =>
                          //     cartAdd(
                          //       item.quantity,
                          //       item.variant_id,
                          //       item.product_id,
                          //       item.product_name,
                          //       cartId,
                          //       cartID
                          //     )
                          //   }
                          // >
                          //   ADD TO CART-2
                          // </button>
                          <CartItemAddToCart variantId={item.variant_id} />
                        ) : null}
                      </div>
                    </div>

                    {item.usage_instructions ? <UsageInstructions usageDirections={item.usage_instructions} /> : null}
                    {item?.ui?.show_rate_product ? (
                      <div className="product-rate-container">
                        <div className="rating-label">
                          <span>Rate Product</span>
                        </div>
                        <div className="rate-product-link">
                          {/* {item.overall_rating ?
                          <ReactStars
                            count={5}
                            // onChange={ratingChanged}
                            value={item.overall_rating}
                            size={24}
                            edit={false}
                            isHalf={false}
                            emptyIcon={<i className="far fa-star"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#579F79"
                            color={'#D3D3D3'}
                          />
                          : */}
                          <CustomRating
                            size={"small"}
                            value={item.overall_rating ? item.overall_rating : overallRating}
                            onChange={(value) => {
                              setOverallRating(value);
                              dispatch(
                                postOverAllFeedback({
                                  order_name: orderName,
                                  product_id: item.product_id,
                                  variant_id: item.variant_id,
                                  customer_id: customerId,
                                  overall_rating: value,
                                  history: history,
                                })
                              );
                            }}
                          />

                          {/* <ReactStars
                            count={5}
                            value={item.overall_rating ? item.overall_rating : overallRating}
                            size={24}
                            isHalf={false}
                            emptyIcon={<i className="far fa-star"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#579F79"
                            color={"#D3D3D3"}
                            onChange={(value) => {
                              setOverallRating(value);
                              
                              dispatch(
                                postOverAllFeedback({
                                  order_name: orderName,
                                  product_id: item.product_id,
                                  variant_id: item.variant_id,
                                  customer_id: customerId,
                                  overall_rating: value,
                                  // customerPhone: customerPhone,
                                  history: history,
                                })
                              );
                            }}
                          /> */}
                          {/* } */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

OrderProducts.propTypes = {
  orderName: PropTypes.string.isRequired,
  itemCount: PropTypes.number.isRequired,
  toggled: PropTypes.bool.isRequired,
  orderItems: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default OrderProducts;
