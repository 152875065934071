// import { draftMode } from "next/headers";
// import { cache } from "react";
import { CMS_URL, CMS_AUTH_TOKEN } from "./constants";

export const getCmsData = async (query, payload = {}) => {
  const isEnabled = true;
  // const { isEnabled } = draftMode();

  const res = await fetch(`${CMS_URL}/graphql`, {
    method: "POST",
    body: JSON.stringify({
      query,
      variables: { publicationState: isEnabled ? "PREVIEW" : "LIVE", ...payload },
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: CMS_AUTH_TOKEN,
    },
  });

  if (!res.ok) {
    const err = new Error("Failed to fetch cms data");
    throw err;
  }

  return res.json();
};

// export const getCmsDataForPathGeneration = cache(async (query, payload = {}) => {
//   const res = await fetch(`${CMS_URL}/graphql`, {
//     method: "POST",
//     body: JSON.stringify({
//       query,
//       variables: { publicationState: "LIVE", ...payload },
//     }),
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: CMS_AUTH_TOKEN,
//     },
//   });

//   if (!res.ok) {
//     const err = new Error("Failed to fetch cms data");
//     throw err;
//   }

//   return res.json();
// });

// -------------------------------------------------------------

const BASE_URL = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/v2`
  : "https://ecomm.api.nathabit.in/v2";

export const getLighteningDeals = async () => {
  const res = await fetch(`${BASE_URL}/api/v2/cart/lightening/deal/variants`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
  });

  if (!res.ok) {
    const err = new Error("Failed to fetch lightening deals");
    throw err;
  }

  const data = await res.json();
  return data;
};
