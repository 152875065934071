import { cartAPI, feedbackAPI } from "../../helpers/api";
import { getRootNavigation } from "../../helpers/rootNavigationData";
import { CMS_URL } from "../../helpers/constants";

export const FETCH_ORDER_LISTING = "FETCH_ORDER_LISTING";
export const SET_ORDER_LISTING = "SET_ORDER_LISTING";
export const FETCH_ORDER_ITEMS = "FETCH_ORDER_ITEMS";
export const SET_ORDER_DETAILS = "SET_ORDER_DETAILS";
export const SET_ORDER_ITEMS = "SET_ORDER_ITEMS";
export const TOGGLE_ORDER_DETAILS = "TOGGLE_ORDER_DETAILS";
export const INCREASE_ORDER_ITEM_QUANTITY = "INCREASE_ORDER_ITEM_QUANTITY";
export const DECREASE_ORDER_ITEM_QUANTITY = "DECREASE_ORDER_ITEM_QUANTITY";
export const CHANGE_ORDER_ITEM_RATE = "CHANGE_ORDER_ITEM_RATE";
export const CHANGE_GENERAL_PRODUCT_RATE = "CHANGE_GENERAL_PRODUCT_RATE";
export const CHANGE_CLEANSING_PRODUCT_RATE = "CHANGE_CLEANSING_PRODUCT_RATE";
export const CHANGE_FRAGRANCE_PRODUCT_RATE = "CHANGE_FRAGRANCE_PRODUCT_RATE";
export const CHANGE_SHELF_LIFE_PRODUCT_RATE = "CHANGE_SHELF_LIFE_PRODUCT_RATE";
export const SET_ORDER_DETAILS_FOR_RATE_PAGE = "SET_ORDER_DETAILS_FOR_RATE_PAGE";
export const SET_PRODUCT_RATING_PARAMETERS = "SET_PRODUCT_RATING_PARAMETERS";
export const ADD_TO_CART_ORDERED_PRODUCT = "ADD_TO_CART_ORDERED_PRODUCT";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const ORDER_ITEMS_ERROR = "ORDER_ITEMS_ERROR";

function getPageType(param = null) {
  let pathname;
  if (param) {
    pathname = param;
  } else {
    pathname = window.location.pathname;
  }
  if (pathname === "/" || pathname[1] === "?") return "homepage";
  const pathSplit = pathname.split("/");

  if (pathSplit.includes("products")) return "product";
  else if (pathSplit.includes("collections")) return "collection";
  else if (pathSplit.includes("blogs")) return "blog";
  else if (pathSplit.includes("pages")) return "shopifyPage";
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function getPageValue(param = null) {
  let pathname;
  if (param) {
    pathname = param;
  } else {
    pathname = window.location.pathname;
  }
  // const pathname = param ? param :
  if (pathname === "/" || pathname[1] === "?") return "Homepage";
  const pathSplit = pathname.split("/");
  return toTitleCase(pathSplit[pathSplit.length - 1].replaceAll("-", " "));
}

export const fetchOrderListing =
  (customerPhone = null, orderName = null) =>
  async (dispatch) => {
    var url = `/v1/checkout/v2/order/track`;
    // Cookies.get("userId") && (url = url + "&customer_id=" + Cookies.get("userId"));
    if (orderName) {
      url += `?order_name=${orderName}`;
    }
    const { data, statusCode } = await cartAPI.get(url);
    if (statusCode === 200) {
      dispatch({
        type: SET_ORDER_LISTING,
        payload: data,
      });

      if (orderName) {
        dispatch(fetchOrderItems(orderName));
      }
    } else {
      dispatch({
        type: ORDER_ITEMS_ERROR,
        payload: {
          error: true,
          errorCode: statusCode,
        },
      });
    }
  };

export const postOverAllFeedback =
  ({ order_name, product_id, variant_id, customer_id, overall_rating, customerPhone, history, feedback = null }) =>
  async (dispatch, getState) => {
    if (order_name && product_id && variant_id) {
      const data = {
        order_name,
        product_id,
        variant_id,
        // customer_id,
        overall_rating,
      };
      const { statusCode } = await cartAPI.post("/v1/checkout/order/item/feedback", data);
      if (statusCode === 200) {
        if (feedback) {
          window.location.href = `https://tracking.nathabit.in/pages/order-tracking/track#/${order_name}/rate-product/${product_id}/${variant_id}`;
        } else {
          history.push(
            history.redirect
              ? `/${order_name}/rate-product/${product_id}/${variant_id}`
              : `/pages/order-tracking/track#/${order_name}/rate-product/${product_id}/${variant_id}`
          );
        }
      }
    }
  };

export const postFeedbackWithParametersFormData =
  (formdata, changePage, customerPhone, orderName, history) => async (dispatch, getState) => {
    fetch(`${feedbackAPI}/v1/checkout/order/item/param/feedback`, {
      // fetch("https://checkout.api.nathabit.in/v1/checkout/order/item/param/feedback", {
      method: "POST",
      body: formdata,
    })
      .then((result) => {
        if (changePage) {
          window.location.assign(`https://tracking.nathabit.in/pages/order-tracking`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

export const postFeedbackWithParameters =
  ({ order_name, product_id, variant_id, overall_rating, review_title, review_details, param_feedback, image }) =>
  async (dispatch, getState) => {
    if (order_name && product_id && variant_id) {
      const formData = {
        order_name,
        product_id,
        variant_id,
        overall_rating,
        review_title,
        review_details,
        param_feedback,
        image,
      };
      const response = await cartAPI.post("/v1/checkout/order/item/param/feedback", formData);

      const { statusCode } = response;

      const customerPhone = getState().order.customer_phone;
      if (statusCode === 200 && customerPhone) {
        window.location.assign(`/checkout/order/track/${customerPhone}`);
      }
    }
  };

export const fetchOrderDetails = (orderName, toggled) => async (dispatch) => {
  if (orderName && !toggled) {
    const { data, statusCode } = await cartAPI.get(`/v1/checkout/v2/order/items?order_name=${orderName}`);

    if (statusCode === 200) {
      dispatch({
        type: SET_ORDER_DETAILS,
        payload: {
          data,
          currentOrder: orderName,
          toggled: false,
        },
      });
    }
  }
};

export const fetchOrderDetailsPage = (orderName) => async (dispatch) => {
  if (orderName) {
    const { data, statusCode } = await cartAPI.get(`/v1/checkout/v2/order/items?order_name=${orderName}`);

    if (statusCode === 200) {
      dispatch({
        type: SET_ORDER_DETAILS,
        payload: {
          data,
          currentOrder: orderName,
          toggled: true,
        },
      });
    }
  }
};

export const toggleOrderDetails = (orderName) => async (dispatch, getState) => {
  const {
    order: { orders },
  } = getState();
  const isToggled = orders.find((order) => order.order_name === orderName)?.toggled;
  if (orderName) {
    if (isToggled === true) {
      const { data, statusCode } = await cartAPI.get(`/v1/checkout/v2/order/items?order_name=${orderName}`);

      if (statusCode === 200) {
        dispatch({
          type: SET_ORDER_DETAILS,
          payload: {
            data,
            currentOrder: orderName,
            toggled: false,
          },
        });
      }
    } else {
      dispatch({
        type: TOGGLE_ORDER_DETAILS,
        payload: {
          orderName,
          toggled: true,
        },
      });
    }
  }
};

export const increaseOrderItemQuantity = (orderName, productId) => ({
  type: INCREASE_ORDER_ITEM_QUANTITY,
  payload: {
    orderName,
    productId,
  },
});

export const decreaseOrderItemQuantity = (orderName, productId) => ({
  type: DECREASE_ORDER_ITEM_QUANTITY,
  payload: {
    orderName,
    productId,
  },
});

export const changeOrderItemRate = (orderName, productId, newRate) => ({
  type: CHANGE_ORDER_ITEM_RATE,
  payload: {
    orderName,
    productId,
    newRate,
  },
});

export const changeGeneralProductRate = (orderName, productId, newRate) => ({
  type: CHANGE_GENERAL_PRODUCT_RATE,
  payload: {
    orderName,
    productId,
    newRate,
  },
});

export const changeCleansingProductRate = (orderName, productId, newRate) => ({
  type: CHANGE_CLEANSING_PRODUCT_RATE,
  payload: {
    orderName,
    productId,
    newRate,
  },
});

export const changeFragranceProductRate = (orderName, productId, newRate) => ({
  type: CHANGE_FRAGRANCE_PRODUCT_RATE,
  payload: {
    orderName,
    productId,
    newRate,
  },
});

export const changeShelfLifeProductRate = (orderName, productId, newRate) => ({
  type: CHANGE_SHELF_LIFE_PRODUCT_RATE,
  payload: {
    orderName,
    productId,
    newRate,
  },
});

export const fetchOrderDetailsForRatePage = (orderName) => async (dispatch) => {
  if (orderName) {
    const { data, statusCode } = await cartAPI.get(`/v1/checkout/v2/order/items?order_name=${orderName}`);

    if (statusCode === 200) {
      dispatch({
        type: SET_ORDER_DETAILS_FOR_RATE_PAGE,
        payload: {
          data,
        },
      });
    }
  }
};

export const addToCartOrderedProduct =
  (quantity, variant_id, product_id, product_name, cartId, cartID) => async (dispatch) => {
    if (variant_id && quantity) {
      const payload = {
        cart_id: cartID,
        id: variant_id,
        quantity: String(quantity),
      };
      const { data, statusCode } = await cartAPI.post(`/v1/checkout/add_cart_item/`, payload);
      let res = data;

      if (statusCode === 200) {
        // var cartItemCount = Object.values(data.cart).reduce((a, b) => a + b, 0);
        const { data, statusCode } = await cartAPI.get(`/v1/checkout/checkout/details?cart_id=${cartID}`);
        let items = 0;
        for (let k in data.cart) {
          items = items + data.cart[k];
        }
        dispatch({
          type: SET_CART_ITEMS,
          payload: items,
        });

        let variant_price = res.data.original_price;
        // try {
        //   Moengage.track_event("Add To Cart", {
        //     product_id: product_id,
        //     variant_id: variant_id,
        //     product_name: product_name
        //       .split("<br>")[0]
        //       .replace("<strong>", "")
        //       .replace("</strong>", "")
        //       .replace("<i>", "")
        //       .replace("</i>", ""),
        //     price: variant_price,
        //     quantity: parseInt(quantity),
        //     is_removed: false,
        //     sku: "-",
        //     referrer_section: "tracking",
        //     referrer_page: getPageType(),
        //     referrer_page_value: getPageValue(),
        //   });
        // } catch (error) {}
      }
    }
  };

export const getProductRatingParameters = (varianId) => async (dispatch) => {
  if (varianId) {
    try {
      const { data, statusCode } = await cartAPI.get(`/v1/checkout/product/rating/parameter/?variant_id=${varianId}`);

      if (statusCode === 200 && data?.length > 0) {
        dispatch({
          type: SET_PRODUCT_RATING_PARAMETERS,
          payload: {
            data,
          },
        });
      }
    } catch (error) {}
  }
};

export const reorderProducts = (orderItems, cartId) => async (dispatch) => {
  for (let i = 0; i < orderItems.length; i++) {
    if (orderItems[i].variant_id && orderItems[i].quantity) {
      const payload = {
        cart_id: cartId,
        id: orderItems[i].variant_id,
        quantity: String(orderItems[i].quantity),
      };
      const { data, statusCode } = await cartAPI.post(`v1/checkout/add_cart_item/`, payload);
      if (statusCode === 200) {
      }
    }
  }
  // window.location.assign(`/?cart_id=${cartId}`);
};

export const trackOrder = (tracking_url) => async (dispatch) => {
  window.location.href = tracking_url;
};

export const fetchOrderItems = (orderName) => async (dispatch) => {
  if (orderName) {
    const { data, statusCode } = await cartAPI.get(`/v1/checkout/v2/order/items?order_name=${orderName}`);

    if (statusCode === 200) {
      dispatch({
        type: SET_ORDER_ITEMS,
        payload: {
          data,
          currentOrder: orderName,
        },
      });
    }
  }
};

export const postOverAllItemFeedback =
  ({ order_name, product_id, variant_id, overall_rating }) =>
  async (dispatch, getState) => {
    if (order_name && product_id && variant_id) {
      const data = {
        order_name,
        product_id,
        variant_id,
        overall_rating,
      };
      const { statusCode } = await cartAPI.post("/v1/checkout/order/item/feedback", data);
      if (statusCode === 200) {
      }
    }
  };

export const fetchNavigation = async () => {
  const payload = {
    query: getRootNavigation,
    variables: { publicationState: "LIVE" },
  };

  const res = await fetch(`${CMS_URL}/graphql`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer ecbbb93b98856c1050b2ab4dbe3b167fa35b179e5f876c428e246002d3faee9bbc9639122499fbdbf06e55c2fcc3e18887cfb0abb99a4c4be233ed2abe78db0d6ef90991b719917b3c3cdb918c232fdd38dcab03de53b8a8fe601dd1708dda37fd5302b41214a3a8c6e123592e2616257ad7ce894fe33a1f0f20a5b4f89d1ed3",
    },
  });
  const resp = await res.json();
  return resp;
};
