const AccountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height="28"
      width="28"
      fill="#404543"
    >
      <title id="account-icon">account-icon</title>

      <path d="M12 3.6c4.6 0 8.4 3.7 8.4 8.4 0 4.6-3.7 8.4-8.4 8.4-4.6 0-8.4-3.7-8.4-8.4 0-4.6 3.8-8.4 8.4-8.4zm.1 11.7c-1.7 0-3.2.7-4.3 1.9 1.1.9 2.6 1.5 4.2 1.5s3.2-.6 4.3-1.6c-1-1.1-2.5-1.8-4.2-1.8zm-.1-10c-3.7 0-6.7 3-6.7 6.7 0 1.5.5 2.9 1.3 4 1.4-1.5 3.3-2.4 5.5-2.4 2.1 0 4 .8 5.3 2.2.8-1.1 1.2-2.4 1.2-3.9.1-3.6-2.9-6.6-6.6-6.6zm0 .9c1.8 0 3.3 1.5 3.3 3.3 0 1.8-1.5 3.3-3.3 3.3-1.8 0-3.3-1.5-3.3-3.3 0-1.8 1.5-3.3 3.3-3.3zm0 1.6c-.9 0-1.7.7-1.7 1.7 0 .9.7 1.7 1.7 1.7s1.7-.7 1.7-1.7c0-.9-.8-1.7-1.7-1.7z"></path>
      <path
        d="M-13.2 19.6c.8-.6 1.4-1.5 1.7-2.5.3-1 .3-2.1-.1-3-.3-1-1-1.8-1.8-2.4-.8-.6-1.9-.9-2.9-.9s-2.1.3-2.9.9c-.8.5-1.4 1.3-1.8 2.3-.3 1-.4 2-.1 3 .3 1 .9 1.9 1.7 2.5-1.4.6-2.6 1.5-3.5 2.7-.9 1.2-1.5 2.6-1.6 4.1v.3c0 .1.1.2.2.3.1.2.3.3.6.3.2 0 .4 0 .6-.2.2-.1.3-.3.3-.6.2-1.6 1-3.1 2.2-4.2 1.2-1.1 2.8-1.7 4.4-1.7 1.6 0 3.2.6 4.4 1.7 1.2 1.1 2 2.6 2.2 4.2 0 .2.1.4.3.5.2.1.4.2.6.2h.1c.2 0 .4-.1.6-.3.1-.2.2-.4.2-.6-.2-1.5-.7-2.9-1.7-4.1-1-1-2.3-2-3.7-2.5zm-3.1-.6c-.7 0-1.3-.2-1.9-.6-.5-.4-1-.9-1.2-1.5-.3-.6-.3-1.3-.2-1.9.1-.6.4-1.2.9-1.7s1.1-.8 1.7-.9 1.3-.1 1.9.2c.6.3 1.1.7 1.5 1.2.4.5.6 1.2.6 1.9 0 .9-.4 1.7-1 2.4-.5.5-1.4.9-2.3.9z"
        className="st0"
      ></path>
    </svg>
  );
};

export default AccountIcon;
