import React, { useState } from "react";
import PropTypes from "prop-types";
// import { Collapse } from "react-collapse";
import { ReactComponent as ExclamationIcon } from "../../../../asset/img/Exclamation.svg";
import Modal from "react-modal";
import "./styles.scss";
import { ReactComponent as CrossIcon } from "../../../../asset/img/Cross.svg";
import { Collapse } from "react-collapse";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "5%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgb(37 34 34)",
    opacity: 0.9,
  },
};

export default function UsageInstructions({ usageDirections }) {
  const [opened, setOpened] = useState(false);
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  const toggleUsageDirections = () => {
    setOpened((opened) => !opened);
  };

  let usageDirectionsFormatted = "";
  if (typeof usageDirections === "string") {
    usageDirectionsFormatted = usageDirections;
  } else if (Array.isArray(usageDirections) && usageDirections.length > 0) {
    usageDirectionsFormatted = usageDirections.join(" ");
  }

  if (!usageDirectionsFormatted) {
    return null;
  }

  return (
    <div className="usage-directions-container">
      {/* <Collapse in={opened} className="usage-directions-collapse" collapsedSize="1.25em">
        <div className="usage-directions-row">
          <div className="exclamation-icon">
            <ExclamationIcon />
          </div>
          <div className="usage-directions">{usageDirectionsFormatted}</div>
        </div>
      </Collapse> */}
      <div className="usage-directions-collapse">
        <div className="usage-directions-row">
          <div className="exclamation-icon">
            <ExclamationIcon />
          </div>
          <div className={`${opened ? "expanded" : "collapsed"}`}>{usageDirectionsFormatted}</div>
        </div>
      </div>

      <div className="usage-direction-btn-container">
        <button
          // onClick={toggleUsageDirections}
          onClick={openModal}
          className="usage-directions-btn"
          alt="Usage Directions"
        >
          Usage Directions
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-header">
          <h2 className="heading">Usage Directions</h2>
          <button onClick={closeModal} className="crossicon">
            <CrossIcon />
          </button>
        </div>
        <div className="usage-instruction-points">
          <ul>
            <li>{usageDirectionsFormatted}</li>
          </ul>
        </div>
      </Modal>
    </div>
  );
}

UsageInstructions.propTypes = {
  usageDirections: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string, PropTypes.number]).isRequired,
};
