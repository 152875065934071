import cookie from "js-cookie";

export const getCartToken = () => {
  let cartToken = "";
  if (cookie.get("cust_cart")) {
    cartToken = cookie.get("cust_cart");
  }

  return cartToken;
};

export const getAuthToken = () => {
  let authToken = "";
  if (cookie.get("access_token")) {
    authToken = `Bearer ${cookie.get("access_token")}`;
  } else if (sessionStorage.getItem("nat_guest_access_token")) {
    authToken = `Bearer ${sessionStorage.getItem("nat_guest_access_token")}`;
  }

  return authToken;
};
