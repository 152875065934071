import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { ReactComponent as AttachIcon } from "../../asset/img/AttachIcon.svg";

import "./scss/dropzone.scss";

const Dropzone = ({ onUpload }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        // onUpload(acceptedFiles.slice(0, 1));
        onUpload(acceptedFiles);
      }
    },
    [onUpload]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*, video/*",
    // maxFiles: 1,
  });

  return (
    <div className="file-field-container">
      <div className="file-field-area" {...getRootProps()}>
        <input {...getInputProps()} />
        <AttachIcon />
        <span>Attach photo or video</span>
      </div>
    </div>
  );
};

export default Dropzone;
