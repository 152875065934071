import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => {
  return (
    <div>
      {/* <Header /> */}
      <div>
        <div>{props.children}</div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;
