const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 23 23"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height="28"
      width="28"
      fill="#404543"
    >
      <title id="search-icon">search-icon</title>
      <path d="M16.4 15.2l3.6 3.6-1.2 1.2-3.6-3.6c-1.3 1-2.9 1.7-4.7 1.7-4.2 0-7.6-3.4-7.6-7.6S6.4 3 10.5 3s7.6 3.4 7.6 7.6c0 1.7-.6 3.4-1.7 4.6zm-1.7-.6c1-1.1 1.7-2.5 1.7-4.1 0-3.2-2.6-5.9-5.9-5.9s-5.9 2.6-5.9 5.9 2.6 5.9 5.9 5.9c1.6 0 3-.6 4.1-1.7l.1-.1z"></path>
      <path d="M25.2-11.9c.8-.6 1.4-1.5 1.7-2.5s.3-2.1-.1-3-1-1.8-1.8-2.4c-.8-.6-1.9-.9-2.9-.9s-2.1.3-2.9.9c-.8.6-1.5 1.5-1.8 2.4s-.4 2-.1 3c.3 1 .9 1.9 1.7 2.5-1.4.6-2.6 1.5-3.5 2.7-.9 1.2-1.5 2.6-1.7 4.1v.3c0 .1.1.2.2.3.1.2.3.3.6.3.2 0 .4 0 .6-.2.2-.1.3-.3.3-.6.2-1.6 1-3.1 2.2-4.2 1.2-1.1 2.8-1.7 4.4-1.7 1.6 0 3.2.6 4.4 1.7 1.2 1.1 2 2.6 2.2 4.2 0 .2.1.4.3.5.2.1.4.2.6.2h.1c.2 0 .4-.1.6-.3.1-.2.2-.4.2-.6-.2-1.5-.7-2.9-1.7-4.1-1-1.1-2.2-2-3.6-2.6zm-3.1-.6c-.7 0-1.3-.2-1.9-.6-.5-.4-1-.9-1.2-1.5-.3-.6-.3-1.3-.2-1.9.1-.6.4-1.2.9-1.7s1.1-.8 1.7-.9 1.3-.1 1.9.2c.6.3 1.1.7 1.5 1.2.4.5.6 1.2.6 1.9 0 .9-.4 1.7-1 2.4-.6.6-1.4.9-2.3.9z"></path>
    </svg>
  );
};

export default SearchIcon;
