import { useState } from "react";
import AddIcon from "../../../asset/svg/AddIcon";
import HideIcon from "../../../asset/svg/HideIcon";

const CategoryDrawerAccordian = ({ category, collectionData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen((previous) => !previous);
  };
  return (
    <>
      <div className="border-b border-categoryDivider">
        <div
          className={`py-[15px] flex justify-between items-center transition-all duration-300 ease-in-out ${
            isOpen && category.items.length > 0 ? "bg-[#D5F3E7]" : ""
          }`}
          onClick={() => {
            const item = {
              refSec: "Hamburger Menu",
              interactionType: isOpen ? "Hide" : "Expand",
              interactionValue: category.title,
            };
            toggleAccordion();
          }}
        >
          {category.items.length > 0 ? (
            <div className="pl-5">
              <p className="Fw400Fs16Hex404543">{category.title}</p>
            </div>
          ) : (
            <div className="px-5 w-full">
              <a href={`https://nathabit.in${category.path}?ref_sec=ham_menu|f_top`}>
                <p className="Fw400Fs16Hex404543">{category.title}</p>
              </a>
            </div>
          )}

          {category.items.length > 0 && (
            <span className="pr-[16px] font-sans font-semibold text-h12 text-content-primary">
              {isOpen ? (
                <div className="w-[18px] h-[14px]">
                  <HideIcon fill={"rgb(62,148,124,1"} />
                </div>
              ) : (
                <div className="w-[18px] h-[14px]">
                  <AddIcon fill={"rgb(62,148,124,1"} />
                </div>
              )}
            </span>
          )}
        </div>

        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out first:border-t ${
            isOpen && category.items.length > 0 ? "max-h-[1000px]" : "max-h-0"
          }`}
        >
          {category.items.map((item) => {
            const filteredCollectionData = collectionData.filter((col) =>
              item.title.toLowerCase().includes(col.attributes.title.toLowerCase())
            );

            return (
              <a key={item.id} href={`https://nathabit.in${item.path}?ref_sec=ham_menu|f_top`}>
                <div className="py-[15px] px-5 w-full  border-b border-categoryDivider">
                  <p className="text-[14px] text-content-5">
                    {item.title}
                    <span className="font-bold text-[10px] text-[#5CAA90] uppercase inline-block align-top pl-[5px]">
                      {filteredCollectionData.length > 0 && filteredCollectionData[0].attributes.superScript
                        ? `${filteredCollectionData[0].attributes.superScript}`
                        : null}
                    </span>
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CategoryDrawerAccordian;
