const BurgerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 22 22"
      version="1.1"
      viewBox="0 0 22 22"
      xmlSpace="preserve"
      width="26"
      height="26"
      fill="#404543"
    >
      <title id="menu">menu</title>
      <path d="M3.4 4.2h15.2v1.7H3.4V4.2zm0 6h10.1v1.7H3.4v-1.7zm0 5.9h15.2v1.7H3.4v-1.7z"></path>
      <path
        d="M-7.1 5.2c.8-.6 1.4-1.5 1.7-2.5s.3-2.1-.1-3c-.3-1-1-1.8-1.8-2.4-.8-.6-1.9-.9-2.9-.9s-2.1.3-2.9.9c-.9.5-1.5 1.3-1.9 2.3s-.4 2-.1 3c.3 1 .9 1.9 1.7 2.5-1.4.6-2.6 1.5-3.5 2.7-.9 1.2-1.5 2.6-1.6 4.1v.3c0 .1.1.2.2.3.1.2.3.3.6.3.2 0 .4 0 .6-.2.2-.1.3-.3.3-.6.2-1.6 1-3.1 2.2-4.2 1.2-1.1 2.8-1.7 4.4-1.7 1.6 0 3.2.6 4.4 1.7 1.2 1.1 2 2.6 2.2 4.2 0 .2.1.4.3.5.2.1.4.2.6.2h.1c.2 0 .4-.1.6-.3.1-.2.2-.4.2-.6-.3-1.3-.9-2.8-1.8-4-.9-1.2-2.1-2.1-3.5-2.6zm-3.1-.6c-.7 0-1.3-.2-1.9-.6-.5-.4-1-.9-1.2-1.5-.3-.6-.3-1.3-.2-1.9.1-.6.4-1.2.9-1.7s1.1-.8 1.7-.9c.6-.1 1.3-.1 1.9.2.6.3 1.1.7 1.5 1.2.4.5.6 1.2.6 1.9 0 .9-.4 1.7-1 2.4-.6.5-1.4.9-2.3.9z"
        className="st0"
      ></path>
    </svg>
  );
};

export default BurgerIcon;
