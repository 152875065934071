import ReactSlidingPane from "react-sliding-pane";
import AllCategoriesDrawerPageIndex from "./AllCategoriesDrawer";

export default function MobileCategoryNavigation({ isOpen, setIsOpen, navigationData }) {
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <ReactSlidingPane
      hideHeader={true}
      from={"left"}
      width={"80%"}
      className={"slide-pane my-class z-[100] h-full"}
      overlayClassName={"slide-pane__overlay z-[100] bottom-pane"}
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "scroll")}
    >
      <AllCategoriesDrawerPageIndex navigationData={navigationData} />
    </ReactSlidingPane>
  );
}
