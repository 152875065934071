import { useCartStore } from "../../../zustStore/useStore";

const CartItemCountOverlay = () => {
  const cartItemCount = useCartStore((state) => state.cartItemCount);
  return (
    <>
      {cartItemCount > 0 ? (
        <span className="absolute top-[-6px] right-[-6px] rounded-full bg-primary w-[18px] h-[18px] text-white font-sans text-sm font-bold leading-tight text-center align-middle pt-[1px]">
          {cartItemCount}
        </span>
      ) : null}
    </>
  );
};

export default CartItemCountOverlay;
