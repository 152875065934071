import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Routes from "./routes/Routes";

import { Provider } from "react-redux";
import store from "./store";

function OrderTracking() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default OrderTracking;
