import fetchFn from "./fetch";

export let cartAPI;
export let authAPI;
export let orderAPI;
export let razorpayKey;
export let cityStateAPI;
export let sentryInit;
export let feedbackAPI;
export let cmsAPI;

// if (process.env.NODE_ENV === "production") {
if (true) {
  feedbackAPI = `https://checkout-service.api.nathabit.in`;
  cartAPI = fetchFn(`https://checkout-service.api.nathabit.in`);
  // cartAPI = fetchFn(`http://localhost:9000`);
  authAPI = fetchFn(`https://authorize.api.nathabit.in`);
  orderAPI = fetchFn(`https://orders.api.nathabit.in`);
  cmsAPI = fetchFn(`https://cms.prod.api.nathabit.in/graphql`);
  cityStateAPI = fetchFn("https://4appt0zuv0.execute-api.ap-south-1.amazonaws.com/dev/api/get/city/");
} else {
  feedbackAPI = `https://checkout.api.stage.nathabit.in`;
  cartAPI = fetchFn(`https://checkout.api.stage.nathabit.in`);
  authAPI = fetchFn(`https://authorize.api.stage.nathabit.in`);
  orderAPI = fetchFn(`https://orders.api.stage.nathabit.in`);
  // cartAPI = fetchFn(`https://checkout.api.nathabit.in`);
  // authAPI = fetchFn(`https://authorize.api.nathabit.in`);
  // orderAPI = fetchFn(`https://orders.api.nathabit.in`);
  cityStateAPI = fetchFn("https://4appt0zuv0.execute-api.ap-south-1.amazonaws.com/dev/api/get/city/");
}
