import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// import ArrowRightIcon from "../../../../asset/img/ArrowRight.svg";
import { ReactComponent as ArrowRightIcon } from "../../../../asset/img/ArrowRight.svg";

import "./styles.scss";

const OrderName = ({ orderName, orderDate, onOrderDetailsClick }) => (
  <div className="order-primary-detail-container justify-start">
    <div className="order-name-container">
      <h5 className="order-name">
        Order Name: <span className="text-[#5c5c5c]">{orderName}</span>
      </h5>
      {onOrderDetailsClick ? (
        <button className="order-details" onClick={onOrderDetailsClick}>
          Details
          <ArrowRightIcon />
        </button>
      ) : null}
    </div>
    <div className="date-container">{orderDate ? moment(orderDate).format("hh:mm a, DD MMM YYYY") : null}</div>
  </div>
);

OrderName.propTypes = {
  orderName: PropTypes.string.isRequired,
  onOrderDetailsClick: PropTypes.func,
};

OrderName.defaultProps = {
  onOrderDetailsClick: null,
};

export default OrderName;
