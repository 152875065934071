import React, { useState, useEffect } from "react";

const Timer = ({ resendOTP }) => {
  const [seconds, setSeconds] = useState(30);
  const [isActive, setIsActive] = useState(true);

  function toggle(e) {
    resendOTP(e);
    setIsActive(!isActive);
    setSeconds(30);
  }

  useEffect(() => {
    let interval = null;
    if (isActive && seconds !== 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (isActive && seconds === 0) {
      setIsActive(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
      <button className="Heading Link Link--primary Text--subdued u-h8" onClick={toggle} disabled={isActive}>
        {isActive ? `00 : ${String("0" + seconds).slice(-2)} s` : "Resend OTP"}
      </button>
    </div>
  );
};

export default Timer;
