import {
  SET_ORDER_LISTING,
  SET_ORDER_DETAILS,
  SET_ORDER_ITEMS,
  TOGGLE_ORDER_DETAILS,
  INCREASE_ORDER_ITEM_QUANTITY,
  DECREASE_ORDER_ITEM_QUANTITY,
  CHANGE_ORDER_ITEM_RATE,
  CHANGE_GENERAL_PRODUCT_RATE,
  CHANGE_CLEANSING_PRODUCT_RATE,
  CHANGE_FRAGRANCE_PRODUCT_RATE,
  CHANGE_SHELF_LIFE_PRODUCT_RATE,
  SET_ORDER_DETAILS_FOR_RATE_PAGE,
  SET_PRODUCT_RATING_PARAMETERS,
  SET_CART_ITEMS,
  ORDER_ITEMS_ERROR,
} from "./actions";

const orderListingInitialValues = {
  loading: true,
  error: false,
  errorCode: null,
  customer_id: "",
  customer_phone: "",
  orders: [],
  currentOrder: "",
  orderDetailsRatePage: null,
  productRatingParameters: [],
  total_items: null,
};

export const orderListingReducer = (state = orderListingInitialValues, action) => {
  switch (action.type) {
    case SET_ORDER_LISTING:
      const selected_order_data = state.orders.find((order) => order.toggled == false);
      return {
        ...state,
        ...action.payload,
        loading: false,
        orders: action.payload.map((order, idx) => ({
          ...order,
          order_items:
            selected_order_data?.order_name == order.order_name && selected_order_data?.order_items
              ? selected_order_data?.order_items
              : [],
          toggled: selected_order_data?.order_name != order.order_name,
        })),
      };
    case SET_ORDER_DETAILS:
      return {
        ...state,
        orders: state.orders.map((order) => {
          return order.order_name === action.payload.data.order_name
            ? {
                ...order,
                order_items: action.payload.data.order_items,
                toggled: action.payload.toggled,
              }
            : { ...order, toggled: true };
        }),
        currentOrder: action.payload.currentOrder,
      };
    case SET_ORDER_ITEMS:
      return {
        ...state,
        orders: state.orders.map((order) => {
          return order.order_name === action.payload.currentOrder
            ? {
                ...order,
                order_items: action.payload.data.order_items,
              }
            : order;
        }),
        currentOrder: action.payload.currentOrder,
      };
    case ORDER_ITEMS_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_ORDER_DETAILS:
      return {
        ...state,
        orders: state.orders.map((order) => {
          return order.order_name === action.payload.orderName
            ? {
                ...order,
                toggled: action.payload.toggled,
              }
            : order;
        }),
      };
    case INCREASE_ORDER_ITEM_QUANTITY:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.order_name === action.payload.orderName) {
            return {
              ...order,
              order_items: order.order_items.map((item) => {
                if (item.product_id === action.payload.productId) {
                  return {
                    ...item,
                    quantity: item.quantity + 1,
                  };
                }
                return item;
              }),
            };
          }
          return order;
        }),
      };
    case DECREASE_ORDER_ITEM_QUANTITY:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.order_name === action.payload.orderName) {
            return {
              ...order,
              order_items: order.order_items.map((item) => {
                if (item.product_id === action.payload.productId && item.quantity) {
                  return {
                    ...item,
                    quantity: item.quantity - 1 === 0 ? item.quantity : item.quantity - 1,
                  };
                }
                return item;
              }),
            };
          }
          return order;
        }),
      };
    case CHANGE_ORDER_ITEM_RATE:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.order_name === action.payload.orderName) {
            return {
              ...order,
              order_items: order.order_items.map((item) => {
                if (item.product_id === action.payload.productId) {
                  return {
                    ...item,
                    overall_rating: action.payload.newRate,
                  };
                }
                return item;
              }),
            };
          }
          return order;
        }),
      };
    case CHANGE_GENERAL_PRODUCT_RATE:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.order_name === action.payload.orderName) {
            return {
              ...order,
              order_items: order.order_items.map((item) => {
                if (item.product_id === action.payload.productId) {
                  return {
                    ...item,
                    general_rating: action.payload.newRate,
                  };
                }
                return item;
              }),
            };
          }
          return order;
        }),
      };
    case CHANGE_CLEANSING_PRODUCT_RATE:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.order_name === action.payload.orderName) {
            return {
              ...order,
              order_items: order.order_items.map((item) => {
                if (item.product_id === action.payload.productId) {
                  return {
                    ...item,
                    cleansing_rating: action.payload.newRate,
                  };
                }
                return item;
              }),
            };
          }
          return order;
        }),
      };
    case CHANGE_FRAGRANCE_PRODUCT_RATE:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.order_name === action.payload.orderName) {
            return {
              ...order,
              order_items: order.order_items.map((item) => {
                if (item.product_id === action.payload.productId) {
                  return {
                    ...item,
                    fragrance_rating: action.payload.newRate,
                  };
                }
                return item;
              }),
            };
          }
          return order;
        }),
      };
    case CHANGE_SHELF_LIFE_PRODUCT_RATE:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (order.order_name === action.payload.orderName) {
            return {
              ...order,
              order_items: order.order_items.map((item) => {
                if (item.product_id === action.payload.productId) {
                  return {
                    ...item,
                    shelf_life_rating: action.payload.newRate,
                  };
                }
                return item;
              }),
            };
          }
          return order;
        }),
      };
    case SET_ORDER_DETAILS_FOR_RATE_PAGE:
      return {
        ...state,
        orderDetailsRatePage: action.payload.data,
      };
    case SET_PRODUCT_RATING_PARAMETERS:
      return {
        ...state,
        productRatingParameters: action.payload.data,
      };
    case SET_CART_ITEMS:
      return {
        ...state,
        total_items: action.payload,
      };
    default:
      return state;
  }
};
