import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const BillDetails = ({
  itemTotalPrice,
  discount,
  shipping,
  subTotalPrice,
  payment_charges,
  promotion_discount_amount,
  redeemed_nat_cash,
  additional_off_value,
}) => {
  return (
    <div className="bill-details-container">
      <h4 className="bill-details-label">Bill Details</h4>
      <div className="bill-details-item">
        <span className="bill-details-item-label">Item Total</span>
        <span className="bill-details-item-value">Rs. {itemTotalPrice}</span>
      </div>
      <div className="bill-details-item">
        <span className="bill-details-item-label">Shipping</span>
        <span className="bill-details-item-value">Rs. {shipping}</span>
      </div>
      {payment_charges ? (
        <div className="bill-details-item">
          <span className="bill-details-item-label">Payment Charge</span>
          <span className="bill-details-item-value">Rs. {payment_charges}</span>
        </div>
      ) : null}
      {discount ? (
        <div className="bill-details-item">
          <span className="bill-details-item-label">Discount</span>
          <span className="bill-details-item-value">Rs. {discount}</span>
        </div>
      ) : null}
      {promotion_discount_amount ? (
        <div className="bill-details-item">
          <span className="bill-details-item-label">Promotion Discount</span>
          <span className="bill-details-item-value">Rs. {promotion_discount_amount}</span>
        </div>
      ) : null}
      {redeemed_nat_cash ? (
        <div className="bill-details-item">
          <span className="bill-details-item-label">Nat Cash Redeemed Off</span>
          <span className="bill-details-item-value">Rs. {redeemed_nat_cash}</span>
        </div>
      ) : null}
      {additional_off_value ? (
        <div className="bill-details-item">
          <span className="bill-details-item-label">Additional Off</span>
          <span className="bill-details-item-value">Rs. {additional_off_value}</span>
        </div>
      ) : null}
      <div className="cash-on-delivery-item">
        <span className="cash-on-delivery-item-label">Total</span>
        <span className="cash-on-delivery-item-value">Rs. {subTotalPrice}</span>
      </div>
    </div>
  );
};

BillDetails.propTypes = {
  itemTotalPrice: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  shipping: PropTypes.number.isRequired,
  subTotalPrice: PropTypes.number.isRequired,
};

export default BillDetails;
