// import { BulbTwoTone } from '@ant-design/icons';
// import { Button } from 'antd';
import React from "react";
// import { Redirect } from "react-router-dom";

import "./styles.scss";

const ChatWithUs = ({ orderName }) => {
  // const [redirect, setRedirect] = useState(false);

  const text = encodeURIComponent(`Need help with order: *${orderName}*`);

  const trackMoengage = () => {
    try {
      window.Moengage.track_event("Clicked Support From Tracking", {
        clicked: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="chat-with-us-container">
      <span>
        Need Help?&nbsp;
        <button
          className="chat-button"
          onClick={() => {
            trackMoengage();
            const url = `https://api.whatsapp.com/send/?phone=918527000777&text=${text}`;
            window.open(url, "_blank");
          }}
        >
          Chat with us
        </button>
        &nbsp;<span style={{ fontSize: "10px" }}>(10AM to 10PM)</span>
      </span>
    </div>
  );
};

export default ChatWithUs;
