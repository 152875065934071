const WishlistIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height="28"
      width="28"
      fill="#404543"
    >
      <title id="heart-icon">heart-icon</title>
      <path d="M15.8 4.1c2.6 0 4.7 2.1 4.7 5.1 0 6-6.4 9.4-8.5 10.7-2.1-1.3-8.5-4.7-8.5-10.7 0-3 2.1-5.1 4.7-5.1 1.6 0 3 .9 3.8 1.7.9-.9 2.3-1.7 3.8-1.7zm-3 13.3c.8-.5 1.4-.9 2.1-1.5 2.5-2 4-4.2 4-6.8 0-2-1.3-3.4-3-3.4-.9 0-1.9.5-2.6 1.2L12 8.2 10.8 7c-.7-.7-1.7-1.2-2.6-1.2-1.7 0-3 1.4-3 3.4 0 2.5 1.4 4.7 4 6.8.6.5 1.3 1 2.1 1.5.3.2.5.3.8.5.2-.3.4-.4.7-.6z"></path>
    </svg>
  );
};

export default WishlistIcon;
