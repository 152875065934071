import React from "react";
import { HashRouter as Router, Switch, useHistory, Route } from "react-router-dom";
import ProductRate from "../components/rating";
import OrderDetails from "../components/orderDetails";

export default function HashRoutes(props) {
  let history = useHistory(props);

  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/:orderName/rate-product/:productId/:variantId"
          exact
          render={(props) => <ProductRate {...props} />}
          // component={<ProductRate />}
        />
        <Route
          path="/details/:orderName"
          exact
          render={(props) => <OrderDetails {...props} />}
          // component={<ProductRate />}
        />
      </Switch>
    </Router>
  );
}
