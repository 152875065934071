import DesktopCategoryNavigation from "./_builder/DesktopCategoryNavigation";
import MobileCategoryNavigation from "./_builder/MobileCategoryNavigation";
import CartItemCountOverlay from "./_builder/CartItemCountOverlay";
import BurgerIcon from "../../asset/svg/BurgerIcon";
import SearchIcon from "../../asset/svg/SearchIcon";
import WishlistIcon from "../../asset/svg/WishlistIcon";
import AccountIcon from "../../asset/svg/AccountIcon";
import CartIcon from "../../asset/svg/CartIcon";
import NatLogo from "../../asset/svg/NatLogo";
import { getCartStatus } from "../../helpers/api/cart";
import { useCartStore } from "../../zustStore/useStore";
import cookie from "js-cookie";
import { useEffect, useState } from "react";
import { getCmsData } from "../../helpers/api/cmsUrl";
import { getRootNavigation } from "../../helpers/api/graphql";
import "react-sliding-pane/dist/react-sliding-pane.css";

const MainHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [navigationData, setNavigationData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getCmsData(getRootNavigation);
      setNavigationData(data.renderNavigation);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCartDetails = async () => {
      try {
        const data = await getCartStatus();
        useCartStore.setState({
          isCartEmpty: Object.keys(data.data).length === 0,
          cartItems: data.data,
          cartItemCount: data.total_cart_items,
          outOfStockIds: data.out_of_stock_ids,
          isLoggedIn: !!cookie.get("access_token"),
        });
      } catch (error) {
        console.error("Failed to fetch cart details:", error);
      }
    };

    fetchCartDetails();
  }, []);

  return (
    <>
      <header className="sticky top-0 z-50 px-[16px] bg-white w-screen desktop:w-full drop-shadow-md flex justify-center">
        <div className="flex justify-between items-center h-[48px] w-full desktop:w-fit desktop:justify-center">
          <div className="flex items-center gap-[15px] max-[325px]:gap-[10px]">
            <button className="desktop:hidden" onClick={() => setIsOpen(true)}>
              <div className="w-[26px] h-[26px]">
                <BurgerIcon />
              </div>
            </button>
            <a href={"https://nathabit.in"}>
              <div className="w-[90px] h-[40px] relative overflow-hidden">
                <NatLogo />
              </div>
            </a>
          </div>

          <DesktopCategoryNavigation navigationData={navigationData} />

          <MobileCategoryNavigation isOpen={isOpen} setIsOpen={setIsOpen} navigationData={navigationData} />

          <div className="flex gap-[15px] max-[325px]:gap-[10px]">
            <div className="flex self-center">
              <a href={"https://nathabit.in/pages/search?nav_bar|f_top"}>
                <div className="w-[26px] h-[26px]">
                  <SearchIcon />
                </div>
              </a>
            </div>

            <div className="flex self-center">
              <a href={"https://nathabit.in/pages/wishlist?nav_bar|f_top"}>
                <div className="w-[26px] h-[26px]">
                  <WishlistIcon />
                </div>
              </a>
            </div>

            <a href={"https://nathabit.in/cart?ref_sec=nav_bar%7Cf_top"}>
              <div className="flex self-center relative">
                <div className="w-[26px] h-[26px]">
                  <CartIcon />
                  <CartItemCountOverlay />
                </div>
              </div>
            </a>

            <a href={"https://nathabit.in/pages/menu?ref_sec=nav_bar%7Cf_top"}>
              <div className="flex self-center">
                <div className="w-[26px] h-[26px]">
                  <AccountIcon />
                </div>
              </div>
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default MainHeader;
