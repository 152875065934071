import { Fragment } from "react";
import HeaderCategoryItem from "./HeaderCategoryItem";

export const Home = () => {
  return (
    <>
      <a href={"https://nathabit.in"} className="min-w-fit">
        <div className="flex items-center pb-2 border-b-[4px] border-b-transparent">
          <p className="text-[13px] text-content-3 text-center">Home</p>
        </div>
      </a>
    </>
  );
};

const HeaderCategoryNavigation = ({ navigation }) => {
  return (
    <div className="hidden desktop:flex mx-auto w-full pt-[16px] pr-[22px] max-[1140px]:pr-[16px]">
      <div className="flex items-center gap-[22px] max-[1140px]:gap-[12px] whitespace-nowrap">
        {navigation.map((item, index) => (
          <Fragment key={item.id}>
            {index === 1 && <Home />}
            <HeaderCategoryItem path={item.path} title={item.title} />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default HeaderCategoryNavigation;
