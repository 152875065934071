import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import OrderName from "./components/OrderName";
import OrderStatus from "./components/OrderStatus";
import OrderButtons from "./components/OrderButtons";
import ErrorMessage from "./components/ErrorMessage";
import OrderProducts from "./components/OrderProducts";
import ChatWithUs from "./components/ChatWithUs";

import { fetchOrderDetails, fetchOrderItems } from "../../store/order/actions";

// import { useQuery } from "../../helpers";
import useWindowDimensions from "../../helpers/hooks/useWindowDimension";

import "../../asset/scss/base.scss";
import "../../asset/scss/form.scss";
import "./scss/styles.scss";

export default function OrderCart({ order, customerId, history }) {
  const {
    order_name,
    order_date,
    tracking_info,
    // tracking_info: { primary_status, primary_date, secondary_status, secondary_date },
    item_count,
    order_items,
    ui: { show_track_button, show_reorder_button },
    error_message,
    toggled,
    tracking_url,
  } = order;

  const userOrders = useSelector((state) => state.order);

  const { currentOrder } = userOrders;

  // const location = useQuery();

  const dispatch = useDispatch();

  const { width } = useWindowDimensions();

  let cartId = Cookies.get("cust_cart");
  // if (cartId ) {
  //   window.location.href = `?cart_id=${cartId.split("=")[1]}`;
  // }

  useEffect(() => {
    if (!toggled) {
      dispatch(fetchOrderItems(order_name));
    }
  }, []);

  return (
    <div className={`order-container ${currentOrder && currentOrder === order_name ? "order-focused" : ""}`}>
      <OrderName
        orderName={order_name}
        orderDate={order_date}
        onOrderDetailsClick={() => {
          try {
            window.Moengage.track_event("Viewed Order Details From Tracking", { viewed: true });
          } catch (error) {
            console.log(error);
          }
          if (width >= 1024) {
            dispatch(fetchOrderDetails(order_name, false));
          } else {
            setTimeout(() => {
              history.push(`/pages/order-tracking/track#/details/${order_name}`);
            }, 1000);
          }
        }}
      />

      {/* <OrderStatus
        primaryStatus={primary_status}
        primaryDate={primary_date}
        secondaryStatus={secondary_status}
        secondaryDate={secondary_date}
      /> */}

      <OrderStatus trackingInfo={tracking_info} />

      <OrderButtons
        showTrackButton={show_track_button}
        showReorderButton={show_reorder_button}
        orderItems={order_items}
        tracking_url={tracking_url}
        cartId={cartId}
      />
      {error_message.message ? (
        <ErrorMessage message={error_message.message} message_type={error_message.message_type} />
      ) : null}

      <OrderProducts
        orderName={order_name}
        itemCount={item_count}
        toggled={toggled}
        orderItems={order_items}
        customerId={customerId}
        history={history}
        cartId={cartId}
      />

      <ChatWithUs orderName={order_name} />
    </div>
  );
}
