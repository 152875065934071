import { useLocation } from "react-router-dom";
import { EMPTY, INVALID, VALID } from "./constants";

export const makePublicURL = (url) => {
  return window.location.origin + "/assets/" + url;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const addClass = (ref, className) => {
  if (ref.current) {
    ref.current.classList.add(className);
  }
};

export const removeClass = (ref, className) => {
  if (ref.current) {
    ref.current.classList.remove(className);
  }
};

export const hasClass = (ref, className) => {
  if (ref.current) {
    return ref.current.classList.contains(className);
  } else {
    return false;
  }
};

export const getTotal = (productDetails, activeDiscount, activeShipping) => {
  let total = 0.0;

  if (productDetails) {
    for (let i = 0; i < productDetails.length; i++) {
      total = total + productDetails[i].productQuantity * productDetails[i].productOriginalPrice;
    }
    total = total - activeDiscount + activeShipping;
  }
  return total;
};

export const getProductTotalForShipping = (productDetails, activeDiscount) => {
  let total = 0.0;
  for (let i = 0; i < productDetails.length; i++) {
    total = total + productDetails[i].productQuantity * productDetails[i].productOriginalPrice;
  }
  total = total - activeDiscount;
  return total;
};

export const mobileNumberValidator = (mobileNumber) => {
  if (mobileNumber.length == 0) {
    return EMPTY;
  } else if (
    // (mobileNumber.length > 9 && mobileNumber.match(/^\+[0-9]{12,13}$/)) ||
    // (mobileNumber.length > 9 && mobileNumber.match(/^[0-9]{10,11}$/))
    mobileNumber.length == 10 &&
    mobileNumber.match(/^[0-9]{10}$/) &&
    !mobileNumber.startsWith(0)
  ) {
    return VALID;
  } else {
    return INVALID;
  }
};

export const pincodeValidator = (pincode) => {
  if (pincode.match(/^[0-9]+$/) && pincode.length == 6) {
    return VALID;
  } else {
    return INVALID;
  }
};

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const round = (value, exp) => {
  if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) return NaN;

  // Shift
  value = value.toString().split("e");
  value = Math.round(+(value[0] + "e" + (value[1] ? +value[1] + exp : exp)));

  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
};
