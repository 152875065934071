import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import OrderCart from "../../components/orderCart";
import OrderDetails from "../../components/orderDetails/OrderDetailsComponent";
import "./scss/orders.scss";
import { fetchOrderListing } from "../../store/order/actions";
import Layout from "../../components/Layout";
import MainHeader from "../../components/MainHeader";
import { OrderNotFound } from "../../components/orderDetails/components/orderNotFound";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

const OrdersPage = ({ history }) => {
  // let [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    let access_token = Cookies.get("access_token");
    // console.log("userPhone", mobile);
    if (access_token) {
      dispatch(fetchOrderListing());
    } else {
      window.location.href = `/account/login`;
    }
  }, [dispatch]);

  const order = useSelector((state) => state.order);
  const { orders, customer_id, loading, error, errorCode } = order;
  const scrollRef = useRef(null);
  const [firstMount, setFirstMount] = useState(false);

  useEffect(() => {
    if (!firstMount) {
      if (orders.length > 0 && window.innerWidth <= 760) {
        window.scrollTo(0, scrollRef.current.offsetTop - 40);
      } else if (orders.length > 0) {
        if (orders.length > 0) {
          window.scrollTo(0, scrollRef.current.offsetTop - 130);
        }
      }
      setFirstMount(true);
    }
  }, []);

  if (error) {
    if (errorCode === 500) {
      return (
        <div className="server-error-container">
          We are facing issues connecting to our servers. Please try again after some time or contact support.
        </div>
      );
    }
  }

  return (
    <Layout>
      <MainHeader />
      {!loading ? (
        <div ref={scrollRef}>
          <div className="orders-tracking-container">
            {orders.length > 0 ? (
              <div className="your-orders-container">
                {orders.map((order, idx) => (
                  <>
                    <div className="custom-container">
                      <div className="custom-heading">Your Orders</div>
                    </div>
                    <OrderCart
                      key={order.order_name}
                      order={order}
                      customerId={customer_id}
                      history={history}
                      open={idx}
                    />
                  </>
                ))}
              </div>
            ) : (
              <OrderNotFound
                title="No Orders Found"
                subtitle="Orders from the last six months are displayed here!"
                buttonText="shop now"
              />
            )}
            <div className="order-details-root-container">
              <OrderDetails history={history} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-[calc(100vh-48px)]">
          <ClipLoader color="#5c5c5c" loading={loading} css={override} size={50} />
        </div>
      )}
    </Layout>
  );
};

export default OrdersPage;
