import React from "react";
import orderNotFoundIcon from "../../../asset/img/OrderNotFound.svg";

export const OrderNotFound = ({ title = "Order Not Found", subtitle, message, buttonText }) => {
  return (
    <div className="h-[calc(100vh-48px)] flex justify-center items-center flex-col">
      <h1 class="Fw700Fs20Hex111714 !text-center desktop:!text-[28px] desktop:!leading-[32px]">{title}</h1>
      <p class="Fw400Fs14HexA0A2A1 !text-center !leading-[20px] desktop:!text-[16px] desktop:!leading-[20px] mt-1">
        {subtitle}
      </p>
      <img src={orderNotFoundIcon} alt="not found" className="my-4" />
      {/* <div class="flex flex-col gap-[4px] desktop:gap-[8px] Fw400Fs14HexA0A2A1 !text-center !leading-[20px] desktop:!text-[16px] desktop:!leading-[20px]">
        <p>{message}</p>
      </div> */}
      <div class="flex items-center justify-center">
        <a
          class="  py-[8px] px-[25px] text-base text-white uppercase font-bold tracking-[0.12em] flex justify-center rounded-[4px] bg-green mx-auto desktop:!text-[18px] desktop:!leading-[28px] desktop:rounded-[8px] desktop:w-fit"
          href={buttonText == "Go Back" ? "/" : "https://nathabit.in"}
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};
