import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { reorderProducts, trackOrder } from '../../../../store/order/actions';

import './styles.scss';

const OrderButtons = (props) => {
  const {
    showTrackButton,
    showReorderButton,
    orderItems,
    tracking_url,
    cartId,
  } = props;
  const dispatch = useDispatch();
  return (
    <div className='order-buttons-container'>
      {showTrackButton ? (
        <button
          className='track-button'
          onClick={() => {
            dispatch(trackOrder(tracking_url));
          }}
        >
          TRACK
        </button>
      ) : null}
      {showReorderButton ? (
        <button
          className='re-order-button'
          onClick={() => {
            dispatch(reorderProducts(orderItems, cartId));
          }}
        >
          RE-ORDER
        </button>
      ) : null}
    </div>
  );
};
OrderButtons.propTypes = {
  showTrackButton: PropTypes.bool.isRequired,
  showReorderButton: PropTypes.bool.isRequired,
};

export default OrderButtons;
