import React from "react";
import HeaderCategoryNavigation from "./HeaderCategoryNavigation";

const DesktopCategoryNavigation = ({ navigationData }) => {
  if (!navigationData) {
    return null;
  }

  return <HeaderCategoryNavigation navigation={navigationData} />;
};

export default DesktopCategoryNavigation;
