import React, { useState } from "react";
// import PropTypes from "prop-types";
import moment from "moment";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { isToday } from "../../../../helpers/utils";
// import { CheckCircleOutlined } from '@ant-design/icons';
// import { Cancel as CancelIcon } from '@material-ui/icons';

import { ReactComponent as PendingLogo } from "../../../../asset/img/Pending.svg";
import { ReactComponent as DeliveredLogo } from "../../../../asset/img/Delivered.svg";
// import { ReactComponent as ShippedLogo } from "../../../../asset/img/Shipped.svg";
// import { ReactComponent as PackageLogo } from "../../../../asset/img/Package.svg";
import { ReactComponent as TrackingPlusIcon } from "../../../../asset/img/TrackingPlus.svg";
import { ReactComponent as MinusIcon } from "../../../../asset/img/TrackingMinus.svg";
import { ReactComponent as ArrowIcon } from "../../../../asset/img/Arrow.svg";
import { ReactComponent as DeliveryTruckIcon } from "../../../../asset/img/DeliveryTruck.svg";

import "./styles.scss";

// const statusLogos = {
//   Pending: <PendingLogo />,
//   Confirmed: <PendingLogo />,
//   "Preparing in Kitchen": <PendingLogo />,
//   Packed: <ShippedLogo />,
//   Shipped: <ShippedLogo />,
//   Delivered: <DeliveredLogo />,
//   Undelivered: <ShippedLogo />,
//   Cancelled: <PendingLogo />,
//   Ordered: <ShippedLogo />,
//   "Expected Date of Delivery": <PackageLogo />,
//   default: <PendingLogo />,
// };

const OrderStatus = (trackingInfo) => {
  // const [trackingDetails, setTrackingDetail] = useState(trackingInfo.trackingInfo);
  const trackingDetails = trackingInfo.trackingInfo;
  const [showAllSubTabs, setshowAllSubTabs] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  let lastSubTabsDate = null;
  if (trackingDetails.length > 1 && trackingDetails[1].sub_tabs && trackingDetails[1].sub_tabs.length > 0) {
    lastSubTabsDate = trackingDetails[1].sub_tabs[trackingDetails[1].sub_tabs.length - 1].date;
  }

  // console.log(trackingDetails);
  // useEffect(() => {
  //   setTrackingDetail(trackingInfo)
  // }, [trackingInfo])

  const handleExpend = () => {
    if (showIcon === true) {
      setTimeout(() => {
        setShowIcon(!showIcon);
      }, 500);
    } else {
      setShowIcon(!showIcon);
    }
    setshowAllSubTabs(!showAllSubTabs);
  };

  return (
    <div className="tracking-status">
      {trackingDetails.length > 0
        ? trackingDetails.map((tracking, idx) => {
            let statuscolor = { fontSize: "14px", color: "#579f79" };
            let customLine = "#e2e0e0";
            let dateColor = { fontSize: "12px", color: "rgb(94, 94, 94)" };
            let iconColor = { background: "rgb(227 241 223)", color: "#fff" };
            if (tracking.primary && !tracking.error) {
              statuscolor = { fontSize: "14px", color: "#579f79" };
            } else if (tracking.primary && tracking.error) {
              statuscolor = { fontSize: "14px", color: "#b00000" };
              iconColor = { background: "rgba(255, 186, 186, 0.2)", color: "#fff" };
            }
            if (trackingDetails.length === idx + 1 && trackingDetails.length > 1) {
              // statuscolor = { fontSize: "14px", color: "rgb(193 183 183)", fontStyle: "italic" };
              // dateColor = { fontSize: "12px", color: "rgb(193 183 183)", fontStyle: "italic" };
              statuscolor = { fontSize: "14px", color: "#828282", fontStyle: "italic" };
              dateColor = { fontSize: "12px", color: "#828282", fontStyle: "italic" };
            }
            return (
              <VerticalTimeline key={idx} lineColor={customLine} layout={"1-column-left"} className="vertical-line">
                {tracking.sub_tabs ? (
                  <>
                    <VerticalTimeline
                      key={idx}
                      lineColor={customLine}
                      layout={"5-column-right"}
                      className="!w-[calc(100%-32px)] !ml-8"
                      // style="width: calc(100% - 32px);"
                    >
                      {/* hide and see older button for subtabs  */}
                      {tracking.sub_tabs.length > 3 && (
                        <div onClick={handleExpend} className="cursor-pointer">
                          <VerticalTimelineElement
                            contentStyle={{ color: "#5c5c5c" }}
                            iconStyle={iconColor}
                            icon={showIcon ? <MinusIcon /> : <TrackingPlusIcon />}
                            className="max-h-[63px]"
                          >
                            {!showIcon && (
                              <span style={statuscolor} className="!text-[#5c5c5c]">
                                ...{tracking.sub_tabs.length - 1}+ more entries
                              </span>
                            )}
                            <div className="text-[12px] text-[#579f79] underline underline-offset-2">
                              {showIcon ? (
                                <span className="flex items-center underline">
                                  Hide &nbsp; <ArrowIcon className="inline-block" />
                                </span>
                              ) : (
                                <span className="flex items-center underline">
                                  View All &nbsp; <ArrowIcon className="inline-block" />
                                </span>
                              )}
                            </div>
                          </VerticalTimelineElement>
                        </div>
                      )}
                      {/* rendering sub_tabs and initially all sub_tabs are hidden exclude last 3 */}
                      {tracking.sub_tabs.map((data, idx) => {
                        return (
                          <VerticalTimelineElement
                            key={idx}
                            iconStyle={iconColor}
                            icon={data.error ? <PendingLogo /> : <DeliveredLogo />}
                            className={`${
                              tracking.sub_tabs.length - idx <= 1 || showAllSubTabs
                                ? "max-h-[500px] opacity-100"
                                : "max-h-0 opacity-20"
                            } transition-all duration-700 ease-in-out `}
                          >
                            <div className="">
                              <div style={statuscolor} className="mr-4">
                                {data.status}
                              </div>
                              <div style={statuscolor} className="!text-[#5c5c5c]">
                                {data.location}
                              </div>
                              <div style={dateColor}>
                                {data.date ? moment(data.date).format("hh:mm a, DD MMM YYYY") : null}
                              </div>
                            </div>
                          </VerticalTimelineElement>
                        );
                      })}
                    </VerticalTimeline>
                  </>
                ) : (
                  <div className={tracking.url ? "ml-8" : ""}>
                    <VerticalTimelineElement
                      // className="vertical-timeline-element--work"
                      contentStyle={{ color: "#5c5c5c" }}
                      iconStyle={iconColor}
                      icon={
                        tracking.error ? <PendingLogo /> : tracking.url ? <DeliveryTruckIcon /> : <DeliveredLogo />
                      }
                    >
                      <span style={statuscolor} className={tracking.url ? "!text-[#5c5c5c]" : ""}>
                        {tracking.status}
                      </span>
                      {/* data get from api  */}
                      <div
                        style={dateColor}
                        className={
                          lastSubTabsDate &&
                          new Date(lastSubTabsDate) > new Date(tracking.date) &&
                          idx + 1 == trackingDetails.length &&
                          tracking.status != "Delivered"
                            ? "line-through"
                            : ""
                        }
                      >
                        {tracking.date ? moment(tracking.date).format("DD MMM YYYY") : null}
                        {isToday(new Date(tracking.date)) &&
                        !(lastSubTabsDate && new Date(lastSubTabsDate) > new Date(tracking.date)) &&
                        idx + 1 == trackingDetails.length
                          ? " (Today)"
                          : null}
                      </div>
                      {/* date in case of delay  */}
                      <div style={dateColor}>
                        {lastSubTabsDate &&
                        new Date(lastSubTabsDate) > new Date(tracking.date) &&
                        idx + 1 == trackingDetails.length &&
                        tracking.status != "Delivered"
                          ? moment(lastSubTabsDate).add(1, "days").format("DD MMM YYYY")
                          : null}
                      </div>
                      <div className="text-[12px] text-[#579f79] underline underline-offset-2">
                        {tracking.url ? (
                          <a href={tracking.url} target="_blank" rel="noreferrer" className="flex items-center">
                            <span className="flex items-center underline">
                              Click here &nbsp; <ArrowIcon className="inline-block" />
                            </span>
                          </a>
                        ) : null}
                      </div>
                    </VerticalTimelineElement>
                  </div>
                )}
              </VerticalTimeline>
            );
          })
        : null}
    </div>
  );
};

// (
//   <div>
//     <div className="order-status-container">
//       <div className="order-status-title d-flex flex-row">Status</div>
//       {primaryStatus ? (
//         <div className="pending-container d-flex flex-row justify-content-between">
//           <div className="order-status d-flex align-items-center">
//             {statusLogos[primaryStatus] || statusLogos.default}
//             {primaryStatus}
//           </div>
//           <div className="order-status">{primaryDate ? moment(primaryDate).format("DD MMM’YY") : null}</div>
//         </div>
//       ) : null}
//       {secondaryStatus ? (
//         <div className="expected-delivery-container d-flex flex-row justify-content-between">
//           <div className="expected-delivery d-flex align-items-center">
//             {statusLogos[secondaryStatus] || statusLogos.default}
//             {secondaryStatus}
//           </div>
//           <div className="expected-delivery">{secondaryDate ? moment(secondaryDate).format("DD MMM’YY") : null}</div>
//         </div>
//       ) : null}
//     </div>
//   </div>
// );

export default OrderStatus;
