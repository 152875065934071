import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const ShippingAddress = ({ shippingAddress, children }) => {
  const { address1, address2, city, name, phone, zip, landmark, province, country_name } = shippingAddress;
  // let address = address2.split(/[:|]/);
  return (
    <div className="shipping-address-container">
      <h4 className="shipping-address-label">Shipping Address</h4>
      <div className="address-item">{name}</div>
      <div className="address-item">{address1}</div>
      <div className="address-item">{address2}</div>
      <div className="address-item">{landmark}</div>
      <div className="address-item">
        {zip}, {city}, {province}, {country_name}
      </div>
      <div className="address-item">{phone}</div>
      {children}
    </div>
  );
};

ShippingAddress.propTypes = {
  shippingAddress: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
};

export default ShippingAddress;
