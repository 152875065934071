const CartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height="28"
      width="28"
      fill="#404543"
    >
      <title id="cart-icon">cart-icon</title>
      <path d="M7.4 3.6h9.3c.3 0 .5.1.7.3l2.3 3v12.6c0 .5-.4.8-.8.8H5.3c-.5 0-.8-.4-.8-.8V6.9l2.3-3c.1-.2.3-.3.6-.3zm10.5 5H6.1v10.1h11.8V8.6zm-.4-1.7l-1.3-1.7H7.8L6.5 6.9h11zm-8 3.4V12c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5v-1.7h1.7V12c0 2.3-1.9 4.2-4.2 4.2-2.3 0-4.2-1.9-4.2-4.2v-1.7h1.7z"></path>
      <path d="M40.5 12.2c.8-.6 1.4-1.5 1.7-2.5.3-1 .3-2.1-.1-3-.3-1-1-1.8-1.8-2.4-.8-.6-1.9-.9-2.9-.9s-2.1.3-2.9.9c-.8.6-1.5 1.5-1.8 2.4-.3 1-.4 2-.1 3 .3 1 .9 1.9 1.7 2.5-1.4.6-2.6 1.5-3.5 2.7-.9 1.2-1.5 2.6-1.6 4.1v.3c0 .1.1.2.2.3.1.2.3.3.6.3.2 0 .4 0 .6-.2.2-.1.3-.3.3-.6.2-1.6 1-3.1 2.2-4.2 1.2-1.1 2.8-1.7 4.4-1.7 1.6 0 3.2.6 4.4 1.7 1.2 1.1 2 2.6 2.2 4.2 0 .2.1.4.3.5.2.1.4.2.6.2.2 0 .4-.1.6-.3.1-.2.2-.4.2-.6-.2-1.5-.7-2.9-1.7-4.1-1-1.1-2.2-2-3.6-2.6zm-3.1-.6c-.7 0-1.3-.2-1.9-.6-.5-.4-1-.9-1.2-1.5-.2-.5-.3-1.2-.2-1.9.2-.6.5-1.2.9-1.7.5-.5 1.1-.8 1.7-.9.6-.1 1.3-.1 1.9.2.6.3 1.1.7 1.5 1.2.4.5.6 1.2.6 1.9 0 .9-.4 1.7-1 2.4-.6.6-1.4.9-2.3.9z"></path>
    </svg>
  );
};

export default CartIcon;
